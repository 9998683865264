import { Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PresbyteryInfoData } from '../types/mdp.types';
import { UserProfile } from '../types/user.types';
import { CLC_FUNCTION } from '../types/constants';
import withUserAllowed from './layout/withUserAllowed';

function NeedHelpModal({
  show,
  presbyteryInfo,
  hide,
} : {
  show: boolean,
  presbyteryInfo: PresbyteryInfoData,
  hide: () => void,
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={hide}
      className="modalstyle"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('Presbytery_Information')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <Form>
          <div className="row">
            <Form.Label className="col-12 fw-bold">
              {t('Organization_Name')}
            </Form.Label>
            <div className="col-12">
              {presbyteryInfo.presbytery}
            </div>
          </div>
          <br />
          <Form.Label className="fw-bold">
            {t('Organization_COM')}
          </Form.Label>
          {presbyteryInfo.users?.map((p: UserProfile) => (
            <div>
              {p.firstName}
              {` ${p.lastName}:`}
              &nbsp;
              &nbsp;
              {p.email}
            </div>
          ))}
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default withUserAllowed(CLC_FUNCTION.manageMDP)(NeedHelpModal);
