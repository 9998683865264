import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { FaFolderOpen } from 'react-icons/fa';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  MDPData,
  MDPResponseData,
  MDPListResponseData,
  MDPDefaultData,
  MatchData,
  MatchResponseData,
  PDPMatchResponseData,
  PresbyteryInfoData,
  PresbyteryInfoDefaultData,
  PresbyteryInfoResponseData,
} from '../types/mdp.types';
import api from '../services/api.service';
import EditButton from '../components/generic/EditButton';
import DeleteButton from '../components/generic/DeleteButton';
import AddButton from '../components/generic/AddButton';
import AlertModal from '../components/generic/AlertModal';
import ConfirmModal from '../components/generic/ConfirmModal';
import ManageButton from '../components/generic/ManageButton';
import ViewButton from '../components/generic/ViewButton';
import PrintButton from '../components/generic/PrintButton';
import ManageMatchingModal from '../components/ManageMatchingModal';
import {
  currentRoleState,
  matchState,
  mdpState,
} from '../services/state.service';
import { Role } from '../types/user.types';
import { SearchCommitteeData } from '../types/ministry.types';
import withUserAllowed from '../components/layout/withUserAllowed';
import { CLC_FUNCTION, MINISTRY_ORG_TYPE } from '../types/constants';
import MDPService from '../services/mdp.service';
import NeedHelpModal from '../components/NeedHelpModal';

function MDPPanel({
  committee,
  updateCallback,
}: {
  committee: SearchCommitteeData | undefined,
  updateCallback: () => void | undefined,
}): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentMDP, setCurrentMDP] = useRecoilState<MDPData>(mdpState);
  const [, setMatch] = useRecoilState<MatchData>(matchState);
  const [list, setList] = useState<MDPData[]>([]);
  const [showDelete, setShowDelete] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [showNoMatchModal, setShowNoMatchModal] = useState(false);
  const [showManageMatchModal, setShowManageMatchModal] = useState(false);
  const [showNeedHelp, setShowNeedHelp] = useState(false);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [presbyteryInfo, setPresbyteryInfo] = useState<PresbyteryInfoData>(PresbyteryInfoDefaultData);

  useEffect(() => {
    if (committee?.id) {
      api.get(`/mdp/?committee=${committee.id}`).then(
        (response: MDPListResponseData) => {
          setList(response.data.sort((a, b) => (parseInt(a.id, 10) - parseInt(b.id, 10))));
        },
      );
    } else {
      api.get('/mdp/').then(
        (response: MDPListResponseData) => {
          setList(response.data.sort((a, b) => (parseInt(a.id, 10) - parseInt(b.id, 10))));
        },
      );
    }

    api.get(`presbyteryinfo/${currentRole.presbytery}/`).then(
      (response: PresbyteryInfoResponseData) => {
        if (response.data.options) {
          setPresbyteryInfo(response.data.options);
        }
      },
    );
  }, [committee?.id, refreshList]);

  const makeNewMDP = useCallback((mdp: MDPData): void => {
    api.post('/mdp/', mdp).then((response: MDPResponseData) => {
      navigate(`/mdp/${(response.data.id)}/`);
    });
  }, [navigate]);

  const closeNewEdit = useCallback((result: boolean, match: MatchData): void => {
    if (result && match) {
      if (match.id > 0) {
        api.put(`/mdp/${match.mdp}/matches/${match.id}/`, match).then(() => {
          MDPService.GetPDPMatches(match.mdp.toString())
            .then((response: PDPMatchResponseData) => {
              if (response.data.length === 0) {
                setShowNoMatchModal(true);
              } else {
                setShowManageMatchModal(false);
                setRefreshList((r: boolean) => !r);
              }
            });
        });
      }
    } else {
      setShowManageMatchModal(false);
    }
  }, []);

  const closeDelete = useCallback((result: boolean): void => {
    if (result && currentMDP) {
      api.delete(`/mdp/${currentMDP.id}/`).then(() => {
        setShowDelete(false);
        setRefreshList((r: boolean) => !r);
      });
    } else {
      setShowDelete(false);
    }
  }, [currentMDP, refreshList, setRefreshList, setShowDelete]);

  const getMatches = (id: string): void => {
    api.get(`/mdp/${id}/matches/`).then(
      (response: MatchResponseData) => {
        if (response.data) {
          const result = response.data[0];
          const matchData = {
            ...result,
            matchCharacteristics: result.matchCharacteristics.sort((a, b) => a.characteristic - b.characteristic),
          };
          setMatch(matchData);
          setShowManageMatchModal(true);
        }
      },
    );
  };

  function renderMatchButtons(option: MDPData): any {
    const callingDate = new Date(option.callingStart);
    const current = new Date();

    return option.hasMatches ? (
      <>
        {currentRole.abbreviation !== 'PNC Member' && (
          ((callingDate && (callingDate > current)) || option.callingStart === '') && (
            <ManageButton
              label=""
              handler={() => {
                getMatches(option.id);
              }}
            />
          )
        )}
        <span className="pr-1">&nbsp;</span>
        <ViewButton
          label=""
          handler={() => (
            navigate(`/mdp/${(option.id)}/matches/`)
          )}
        />
      </>
    ) : (
      currentRole.abbreviation !== 'PNC Member' && (
        <AddButton
          label=""
          handler={() => {
            getMatches(option.id);
          }}
        />
      )
    );
  }

  function renderPrintButton(id: string): any {
    return (
      <>
        <PrintButton
          label=""
          handler={() => (
            navigate(`/mdp/${id}/view/`)
          )}
        />
        <span className="pr-1">&nbsp;</span>
      </>
    );
  }

  function renderEditButtons(option: MDPData): JSX.Element {
    const callingDate = new Date(option.callingStart);
    const current = new Date();
    return (
      <>
        {((callingDate && (callingDate > current)) || option.callingStart === '') && (
          <EditButton
            label=""
            handler={() => (
              navigate(`/mdp/${(option.id)}/`)
            )}
          />
        )}
        <span className="pr-1">&nbsp;</span>
        {((callingDate && (callingDate > current)) || option.callingStart === '') && (
          <DeleteButton
            label=""
            handler={() => {
              setCurrentMDP(option);
              setShowDelete(true);
            }}
          />
        )}
      </>
    );
  }

  function getStatus(mdp: MDPData): string {
    const callingDate = new Date(mdp.callingStart);
    const current = new Date();

    if (!mdp.submitted) {
      return t('InProgress');
    }

    if (mdp.submitted && !mdp.authorized) {
      return t('Submitted');
    }

    if (callingDate && (callingDate <= current)) {
      return t('Calling_Notify_Completed');
    }

    if (callingDate && (callingDate > current)) {
      return t('Calling_Notify_Pending');
    }

    return t('Released');
  }

  function canViewEditButtons(role: Role, mdp: MDPData): boolean {
    if (role.abbreviation !== 'PNC Member') {
      if (role.abbreviation === 'PNC Chair') {
        return true;
      }
      if (role.organizationType === MINISTRY_ORG_TYPE.presbytery
        && mdp.authorized
        && mdp.submitted) {
        return true;
      }
      if (role.organizationType === MINISTRY_ORG_TYPE.congregation) {
        return true;
      }
    }
    return false;
  }

  function renderPDPTable(pdpList: MDPData[]): JSX.Element {
    return (
      <tbody>
        {pdpList?.map((option: MDPData) => (
          <tr key={option.id} className="dividinglines">
            <td>{option.id}</td>
            <td>
              {option.positionType ? option.positionType : ''}
              {option.positionTitle ? ` (${option.positionTitle})` : ''}
            </td>
            <td>{option.organizationName ? option.organizationName : '' }</td>
            <td>{getStatus(option)}</td>
            <td>
              <div>
                <ManageMatchingModal
                  callback={closeNewEdit}
                  show={showManageMatchModal}
                  hide={() => { setShowManageMatchModal(false); setRefreshList((r: boolean) => !r); }}
                />
                {option.submitted && renderPrintButton(option.id)}
                { canViewEditButtons(currentRole, option) && (renderEditButtons(option))}

                <span className="pr-1">&nbsp;</span>
                {currentRole.functions.indexOf(CLC_FUNCTION.viewMatches) > -1
                  && option.submitted && option.authorized && renderMatchButtons(option)}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }

  return (
    <div className="mb-3 mx-auto mobiletable col-lg-12">
      {committee && (
        <div>
          <FaFolderOpen />
          <span className="accordion-header-label">
            {t('MDP._Accordion_Title')}
          </span>
        </div>
      )}
      <div className="d-flex flex-row-reverse">
        {currentRole.functions.indexOf(CLC_FUNCTION.manageMDP) > -1 && (
          <div className="mx-1">
            <Button
              onClick={() => (setShowNeedHelp(true))}
              className="mb-3 ms-auto createbutton"
              variant="primary"
              size="sm"
              active
            >
              {`+ ${t('Need_Help')}`}
            </Button>
          </div>
        )}
        <div>
          {currentRole.functions.indexOf(CLC_FUNCTION.manageMDP) > -1 && (
            <Button
              onClick={() => (makeNewMDP({
                ...MDPDefaultData,
                organization: committee?.organization,
                committee: committee?.id,
              }))}
              className="mb-3 ms-auto createbutton"
              variant="primary"
              size="sm"
              active
            >
              {`+ ${t('Create_MDP')}`}
            </Button>
          )}
        </div>
      </div>
      <NeedHelpModal
        show={showNeedHelp}
        presbyteryInfo={presbyteryInfo}
        hide={() => setShowNeedHelp(false)}
      />
      <ConfirmModal
        show={showDelete}
        title={t('PDP.Confirm')}
        description={t('MDPDeleteConfirmationMessage')}
        yesLabel={t('PDP.Delete')}
        noLabel={t('PDP.Cancel')}
        callback={closeDelete}
      />
      <AlertModal
        show={showNoMatchModal}
        title={t('NoMatch')}
        description={t('NoMatchDescription')}
        closeLabel={t('OK')}
        callback={() => setShowNoMatchModal(!showNoMatchModal)}
      />
      <div className="border border-dark rounded-3">
        <Table responsive hover className="mb-3 linktable">
          <thead>
            <tr className="linktableheader">
              <th>{t('Links_ID')}</th>
              <th>{t('PDP.Position')}</th>
              <th>{t('Organization')}</th>
              <th>{t('Status')}</th>
              <th>{t('Links_Table_Actions')}</th>
            </tr>
          </thead>
          { renderPDPTable(list) }
        </Table>
      </div>
    </div>
  );
}

export default withUserAllowed([CLC_FUNCTION.manageMDP, CLC_FUNCTION.viewMatches].join(','))(MDPPanel);
