import {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import Table from 'react-bootstrap/Table';
import { Editor } from '@tinymce/tinymce-react';
import api from '../services/api.service';
import { PDPNarrativeData, PDPNarrativesResponseData } from '../types/pdp.types';
import { useStepValid } from '../hooks/useStepValid';
import { StepProps, StepWizardData } from '../types/generic.types';
import { pdpStepState } from '../services/state.service';
import AlertModal from './generic/AlertModal';
import { cleanText } from '../utils';

function PDPNarrative({
  id,
}: StepProps): JSX.Element {
  const { t } = useTranslation();
  const [PDPNarratives, setPDPNarratives] = useState<PDPNarrativeData[]>([]);
  const [, setSteps] = useRecoilState<StepWizardData[]>(pdpStepState);
  const [ref, setRef] = useState(0);
  const editorRef = useRef<any>(null);
  const requiredFields = useMemo(() => [], []);
  const [showAlert, setShowAlert] = useState(false);
  const [validContentLen, setValidContentLen] = useState(true);

  const handleEditorChange = useCallback((content: string, key: string, narrativeId: number): void => {
    const characterLimit = 1700;
    const cleanedText = cleanText(content);
    if (cleanedText.length > characterLimit) {
      setShowAlert(true);
      setValidContentLen(false);
    } else {
      setValidContentLen(true);
    }

    setPDPNarratives(PDPNarratives.map((r: any) => ((r.id === narrativeId ? { ...r, [key]: content } : r))));
  }, [PDPNarratives]);

  useStepValid('narratives', PDPNarratives, requiredFields, pdpStepState);

  useEffect(() => {
    api.get(`/pdp/${id}/narrative/`).then(
      (response: PDPNarrativesResponseData) => {
        const sortedData = response.data.sort((a, b) => a.displayOrder - b.displayOrder);
        setPDPNarratives(sortedData);
      },
    );
  }, [id]);

  useEffect(() => {
    if (ref > 0) {
      const option = PDPNarratives.filter((e: any) => e.id === ref)[0];
      if (option.response) {
        // Strip out font-weight styling from spans
        option.response = option.response.replace(/(<span[^>]*?)style="[^"]*?font-weight:[^";]*;?([^"]*?)"([^>]*>)/g, '$1style="$2"$3');
      }
      api.put(`/pdp/${id}/narrative/${ref}/`, option).then(() => {
        // todo: add saved indicator, and add exception handler
      });
    }
    setRef(0);
  }, [PDPNarratives, ref]);

  useEffect(() => {
    if (validContentLen) {
      setSteps((p: StepWizardData[]) => (
        p.map((b: StepWizardData) => {
          if (b.key === 'narratives') {
            return { ...b, isValid: true };
          }

          return b;
        })
      ));
    } else {
      setSteps((p: StepWizardData[]) => (
        p.map((b: StepWizardData) => {
          if (b.key === 'narratives') {
            return { ...b, isValid: false };
          }

          return b;
        })
      ));
    }
  }, [PDPNarratives]);

  return (
    <div className="mb-3 mx-auto mobiletable col-lg-8">
      <AlertModal
        show={showAlert}
        title={t('Character_Limit_Exceeded')}
        description={t('1000_Char_Limit_Description')}
        closeLabel={t('OK')}
        callback={() => setShowAlert(!showAlert)}
      />
      <div className="title">{t('PDP_Narratives')}</div>
      <div className="col-12 mb-3">
        {t('PDP_Narratives_Description')}
      </div>
      {
        PDPNarratives.map((option: PDPNarrativeData) => (
          <div key={option.id} className="border border-dark rounded-3 mb-3">
            <Table className="pdp-narrative-panel">
              <thead>
                <tr className="text-center fw-bold narrative-question">
                  <td>{option.question}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="text-start">
                      {t('Applies_To_Position')}
                      &nbsp;
                      {option.positionTypes}
                    </div>
                    <hr />
                    <div className="mb-1">
                      <span>Response:</span>
                    </div>
                    <Editor
                      onInit={(evt: any, editor: any): any => { editorRef.current = editor; }}
                      value={option.response || ''}
                      onEditorChange={(content) => handleEditorChange(content, 'response', option.id)}
                      init={{
                        menubar: false,
                        statusbar: false,
                        branding: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code autoresize',
                        ],
                        toolbar: 'fontselect | formatselect | '
                          + 'alignleft aligncenter alignright alignjustify |'
                          + 'bold italic strikethrough underline | forecolor backcolor link bullist numlist',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        init_instance_callback(editor: any) {
                          editor.on('blur', () => { setRef(option.id); });
                        },
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        ))
      }
    </div>
  );
}

export default PDPNarrative;
