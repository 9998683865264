export const MIN_SALARY = 5000;
export const MAX_SALARY = 250000;
export const GAP_SALARY = 5000;
export const MIN_SCORE = 0;
export const MAX_SCORE = 100;
export const GAP_SCORE = 5;
export const STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
  'PR',
];

export const ORG_TYPE = [
  'Church',
  'Other',
];

export const ATTESTATION_STATUSES = {
  passed: 'passed',
};

export const CLC_FUNCTION = {
  callSeeker: 'Call Seeker',
  attestOrdained: 'Attest Profile (Ordained)',
  attestCandidate: 'Attest Profile (Candidate)',
  attestNonOrdained: 'Attest Profile (Non-ordained)',
  manageOrganization: 'Manage Organization',
  advancedSearch: 'Advanced Search',
  approveMDP: 'Approve MDP',
  manageMDP: 'Manage MDP',
  manageMatching: 'Manage Matching',
  viewMatches: 'View Matches',
  createSearchCommittee: 'Create Search Committee',
  manageSearchCommittees: 'Manage Search Committees',
  manageCongregationProfile: 'Manage Congregation Profile',
  notifyCall: 'Notify a Call',
  approveCOS: 'Approve COS',
};

export const MATCH_ACTION_CHOICES = {
  invited: 'Invite to apply',
  willNotConsider: 'Will not consider',
  saveForLater: 'Save for later',
  requiresAction: 'Requires action',
  willNotConsiderFurther: 'Will not consider further',
  invitedToAcceptCall: 'Invited to accept the call',
  beginConsideration: 'Begin consideration',
  reconsider: 'Reconsider',
};

export const STATUS_CHOICES = {
  invitedToApply: 'Invited to apply',
  acceptedinvitation: 'Accepted invitation',
  beingConsidered: 'Being considered',
  notConsidered: 'Not considered',
  noLongerConsidered: 'No longer considered',
  rejectedCall: 'Rejected call',
  acceptedCall: 'Accepted call',
  requiresAction: 'Requires action',
  offeredCall: 'Offered call',
  referred: 'Referred',
  callPending: 'Call Pending',
  callCompleted: 'Call Completed',
  declinedWithdrawn: 'Declined/Withdrawn',
  requiresPresbyteryAction: 'Requires Presbytery Action',
  notConsideredByPresbytery: 'Not Considered By Presbytery',
};

export const PDP_REQUIRES_ACTIONS = {
  invitedToApply: {
    willNotConsider: 'Will not consider',
  },
  requiresAction: {
    invited: 'Invite to apply',
    willNotConsider: 'Will not consider',
  },
  acceptedReferral: {
    acceptReferral: 'Accept Referral',
  },
  referPDP: {
    refer: 'Refer',
  },
};

export const PDP_DISCERNMENT_ACTIONS = {
  acceptedInvitation: {
    beginConsideration: 'Begin consideration',
    willNotConsider: 'Will not consider further',
  },
  beingConsidered: {
    willNotConsiderFurther: 'Will not consider further',
    notifyACall: 'Notify a call',
  },
  callPending: {
    willNotConsiderFurther: 'Will not consider further',
  },
};

export const PDP_NOT_CONSIDERED_ACTIONS = {
  notConsidered: {
    invited: 'Invite to apply',
  },
  noLongerConsidered: {
    reconsider: 'Reconsider',
  },
};

export const SAVED_OPPORTUNITY_OPTIONS = [
  'Apply',
  'Remove_Opportunity',
];

export const REMOVE_OPPORTUNITY_OPTION = [
  'Remove_Opportunity',
];

export const ECCSTATUS_CHOICES = {
  minister: 'minister',
  candidate: 'candidate',
  undercare: 'undercare',
  cce: 'cce',
  rulingelder: 'rulingelder',
  member: 'member',
  retiredMinister: 'retired minister',
};

export const MINISTRY_ORG_TYPE = {
  presbytery: 'presbytery',
  congregation: 'congregation',
  synod: 'synod',
};

export const PRESBYTERY_MATCHING_OPTIONS = {
  none: 'None',
  ordained: 'All Ordained',
  committee: 'Specific Positions',
};
