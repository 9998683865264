import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import MDPService, {
  RequirementsData,
  RequirementsResponseData,
  CompensationData,
  CompensationResponseData,
  LanguageData,
  LanguageResponseData,
} from '../services/mdp.service';
import {
  MDPData,
  MinistryRequirementsData,
  MinistryRequirementsResponseData,
  MDPReferenceResponseData,
  MDPNarrativeData,
  PNCChairData,
  PNCChairResponseData,
  PNCChairDefaultData,
  CertificateData,
  CertificateResponseData,
  MDPNarrativesResponseData,
  MDPOptionalLinkData,
  MDPOptionalLinkResponseData,
} from '../types/mdp.types';
import {
  CongregationData,
  CongregationResponseData,
} from '../types/pdp.types';
import api from '../services/api.service';
import { mdpStepState, mdpState } from '../services/state.service';
import { StepProps } from '../types/generic.types';
import { useStepValid } from '../hooks/useStepValid';
import SanitizeHTML from '../services/html.service';

function Review({
  setActiveStep,
  steps,
}: StepProps): JSX.Element {
  const { t } = useTranslation();
  const mdp = useRecoilValue<MDPData>(mdpState);
  const [requirements, setRequirements] = useState<RequirementsData>();
  const [certificateOptions, setCertificateOptions] = useState<CertificateData[]>([]);
  const [languageOptions, setLanguageOptions] = useState<LanguageData[]>([]);
  const [compensation, setCompensation] = useState<CompensationData>();
  const [ministryRequirements, setMinistryRequirements] = useState<MinistryRequirementsData>();
  const [ministryReference, setMinistryReference] = useState<any>([]);
  const [chairContact, setChairContact] = useState<PNCChairData>(PNCChairDefaultData);
  const [MDPNarratives, setMDPNarratives] = useState<MDPNarrativeData[]>([]);
  const [yokedMinistryList, setYokedMinistryList] = useState<CongregationData[]>([]);
  const [optionalLink, setOptionalLink] = useState<MDPOptionalLinkData[]>([]);

  const requiredFields = useMemo(() => [], []);
  useStepValid('review', mdp, requiredFields, mdpStepState);

  useEffect(() => {
    api.get(`/mdp/${mdp.id}/requirements/`).then(
      (response: RequirementsResponseData) => (setRequirements(response.data[0])),
    );
    api.get(`/mdp/${mdp.id}/compensation/`).then(
      (response: CompensationResponseData) => {
        if (response.data) {
          setCompensation({ ...response.data[0] });
        }
      },
    );
    api.get('/languages/').then(
      (response: LanguageResponseData) => (setLanguageOptions(response.data.options)),
    );
    api.get('/certificates/').then(
      (response: CertificateResponseData) => (setCertificateOptions(response.data.options)),
    );
    api.get(`/mdp/${mdp.id}/ministryrequirements/`).then(
      (response: MinistryRequirementsResponseData) => (setMinistryRequirements(response.data[0])),
    );
    api.get(`/mdp/${mdp.id}/ministryreference/`).then(
      (response: MDPReferenceResponseData) => (setMinistryReference(response.data)),
    );
    api.get(`/mdp/${mdp.id}/chair/`).then(
      (response: PNCChairResponseData) => (setChairContact(response.data[0])),
    );
    api.get(`/mdp/${mdp.id}/narrative/`).then(
      (response: MDPNarrativesResponseData) => (setMDPNarratives(response.data)),
    );
    api.get(`/mdp/${mdp.id}/mdpoptionallinks/`).then(
      (response: MDPOptionalLinkResponseData) => (setOptionalLink(response.data)),
    );

    MDPService.GetYokedMinistries(mdp.id).then(
      (response: CongregationResponseData) => { setYokedMinistryList(response.data.congregations); },
    );
  }, [mdp.id]);

  return (
    <div className="col-8 mx-auto">
      <div className="mx-auto col-12">
        <div className="title">{t('Review_MDP')}</div>
        <div className="mb-3">{t('Review_MDP_Detail')}</div>

        {/* Position Requirements */}
        <div className="border border-dark rounded-3 text-start mb-3">
          <div className="row">
            <div className="col-9 mt-4 mb-3 ms-3 fw-bold h4">{t('Position_Requirements')}</div>
            <Button
              type="button"
              onClick={() => setActiveStep(steps[0])}
              data-step-key="positionRequirements"
              className="col-2 my-4 ms-3 text-end text-primary bg-white border-0"
            >
              <>{t('PDP.Edit')}</>
            </Button>
          </div>
          <hr className="mdpdivider" />
          <div className="row reviewscroll">
            <div className="col-6">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Position_Type_Title')}
                </div>
                {requirements?.positionTypeDescription}
              </div>
              {requirements?.positionTypeDescription === 'Pastor, Yoked Ministry' && (
                <div className="my-4 mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Yoked_Ministries')}
                  </div>
                  <div>
                    {yokedMinistryList.map((option: CongregationData) => (
                      <div>
                        {option.name}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="my-4 mx-3">
                <div className="mb-2 reviewtitle">
                  {t('MDP.Experience_Required')}
                </div>
                {requirements?.experienceLevelDescription}
              </div>
              {requirements?.title && (
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Specify_Title')}
                  </div>
                  {requirements?.title}
                </div>
              )}
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Employment_Status')}
                </div>
                {requirements?.employmentTypeDescription}
              </div>
              {requirements?.certificate?.length !== 0 && (
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Training_Cert_Req')}
                  </div>
                  <div>
                    {requirements?.certificate.map((option: any) => (
                      <div key={`certrequirement-${option}`}>
                        {certificateOptions.map((item: any) => (
                          (item.id === option)
                            ? (
                              <div key={`certoption-${item.id}`}>
                                {item.description}
                              </div>
                            )
                            : null))}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="col-6">
              {requirements?.othercert && (
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Other_Training')}
                  </div>
                  {requirements?.othercert}
                </div>
              )}
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Language_Requirements')}
                </div>
                <div>
                  {requirements?.language.map((option: any) => (
                    <div key={`langitem-${option.id}`}>
                      {languageOptions.map((item: any) => (
                        (item.id === option)
                          ? (
                            <div key={`langoption-${item.id}`}>
                              {item.description}
                            </div>
                          )
                          : null))}
                    </div>
                  ))}
                </div>
              </div>
              {requirements?.otherlang && (
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Other_Languages')}
                  </div>
                  {requirements?.otherlang}
                </div>
              )}
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('MDP.Statement_Required')}
                </div>
                {requirements?.statementRequired ? 'Yes' : 'No'}
              </div>
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Clergy_Couple')}
                </div>
                {requirements?.filingCouple ? 'Yes' : 'No'}
              </div>
              {requirements?.deadline && (
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Application_Deadline')}
                  </div>
                  {requirements?.deadline?.toString() || ''}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Ministry Requirements */}
        <div className="border border-dark rounded-3 text-start mb-3">
          <div className="row">
            <div className="col-9 mt-4 mb-3 ms-3 fw-bold h4">{t('MDP.Ministry_Requirements')}</div>
            <Button
              type="button"
              onClick={() => setActiveStep(steps[1])}
              data-step-key="ministryRequirements"
              className="col-2 my-4 ms-3 text-end text-primary bg-white border-0"
            >
              <>{t('PDP.Edit')}</>
            </Button>
          </div>
          <hr className="mdpdivider" />
          <div className="row reviewscroll">
            <div className="col-12">
              <div className="my-4 mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Church_Mission')}
                </div>
                <SanitizeHTML html={ministryRequirements?.vision} />
              </div>
              <div className="my-5 mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Tasks_Expectations')}
                </div>
                <SanitizeHTML html={ministryRequirements?.tasks} />
              </div>
            </div>
          </div>
        </div>

        {/* Compensation & Housing */}
        <div className="border border-dark rounded-3 text-start mb-3">
          <div className="row">
            <div className="col-9 mt-4 mb-3 ms-3 fw-bold h4">{t('Compensation_Housing')}</div>
            <Button
              type="button"
              onClick={() => setActiveStep(steps[2])}
              data-step-key="compensationAndHousing"
              className="col-2 my-4 ms-3 text-end text-primary bg-white border-0"
            >
              <>{t('PDP.Edit')}</>
            </Button>
          </div>
          <hr className="mdpdivider" />
          <div className="row reviewscroll">
            <div className="col-6">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('PDP.Min_Expected_Salary')}
                </div>
                {compensation?.minimumExpectedSalary}
              </div>
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('PDP.Max_Expected_Salary')}
                </div>
                {compensation?.maximumExpectedSalary}
              </div>
            </div>
            <div className="col-6">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Housing_Type')}
                </div>
                {compensation?.housingType}
              </div>
            </div>
          </div>
        </div>

        {/* Narratives */}
        <div className="border border-dark rounded-3 text-start mb-3">
          <div className="row">
            <div className="col-9 mt-4 mb-3 ms-3 fw-bold h4">{t('Narratives')}</div>
            <Button
              type="button"
              onClick={() => setActiveStep(steps[3])}
              data-step-key="narratives"
              className="col-2 my-4 ms-3 text-end text-primary bg-white border-0"
            >
              <>{t('PDP.Edit')}</>
            </Button>
          </div>
          <hr className="mdpdivider" />
          <div className="row reviewscroll">
            <div className="col-11 mt-4 ms-auto me-5">
              {MDPNarratives.map((option: MDPNarrativeData) => (
                <div key={`narrative-${option.id}`} className="border border-dark rounded-3 mb-3">
                  <Table className="mdp-narrative-panel">
                    <thead>
                      <tr className="linktableheader">
                        <td>{option.question}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <hr />
                          <div className="mb-1">
                            <span>Response: </span>
                            <SanitizeHTML html={option.response || ''} />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Optional Links */}
        <div className="border border-dark rounded-3 text-start mb-4">
          <div className="row">
            <div className="col-9 mt-4 mb-3 ms-3 fw-bold h4">{t('MDP.Optional_Links')}</div>
            <Button
              type="button"
              onClick={() => setActiveStep(steps[4])}
              data-step-key="optionallinks"
              className="col-2 my-4 ms-3 text-end text-primary bg-white border-0"
            >
              {t('MDP.Edit')}
            </Button>
          </div>
          <hr className="mdpdivider" />
          <div className="row reviewscroll">
            <div className="col-6">
              <div className="my-4  mx-3">
                {optionalLink.map((option: any) => (
                  <div key={`optionallinks-${option.id}`} className="text-start my-4 mx-3">
                    <b>{option.linkTitle}</b>
                    &nbsp;-&nbsp;
                    {option.linkDescription}
                    &nbsp;-&nbsp;
                    <a
                      href={(option.linkUrl).startsWith('http') ? option.linkUrl : (`//${option.linkUrl}`)}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      {option.linkUrl}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* No need to display Equal Employment Opportunity steps[5] */}
        {/* References */}
        <div className="border border-dark rounded-3 text-start mb-3">
          <div className="row">
            <div className="col-9 mt-4 mb-3 ms-3 fw-bold h4">{t('MDP.References')}</div>
            <Button
              type="button"
              onClick={() => setActiveStep(steps[6])}
              data-step-key="references"
              className="col-2 my-4 ms-3 text-end text-primary bg-white border-0"
            >
              <>{t('PDP.Edit')}</>
            </Button>
          </div>
          <hr className="mdpdivider" />
          <div className="row reviewscroll">
            <div className="col-4">
              <div className="my-4 mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Reference_One')}
                </div>
                <p>
                  {ministryReference[0]?.name}
                  <br />
                  {ministryReference[0]?.relation}
                  <br />
                  {ministryReference[0]?.phone}
                  <br />
                  {ministryReference[0]?.email}
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="my-4 mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Reference_Two')}
                </div>
                <p>
                  {ministryReference[1]?.name}
                  <br />
                  {ministryReference[1]?.relation}
                  <br />
                  {ministryReference[1]?.phone}
                  <br />
                  {ministryReference[1]?.email}
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="my-4 mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Reference_Three')}
                </div>
                <p>
                  {ministryReference[2]?.name}
                  <br />
                  {ministryReference[2]?.relation}
                  <br />
                  {ministryReference[2]?.phone}
                  <br />
                  {ministryReference[2]?.email}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* PNC Chair */}
        <div className="border border-dark rounded-3 text-start mb-3">
          <div className="row">
            <div className="col-9 mt-4 mb-3 ms-3 fw-bold h4">{t('PNC_Chair_Contact')}</div>
            <Button
              type="button"
              onClick={() => setActiveStep(steps[7])}
              data-step-key="pncChair"
              className="col-2 my-4 ms-3 text-end text-primary bg-white border-0"
            >
              {t('PDP.Edit')}
            </Button>
          </div>
          <hr className="mdpdivider" />
          <div className="row reviewscroll">
            <div className="col-6">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Name')}
                </div>
                {chairContact?.name}
              </div>
            </div>
            <div className="col-6">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Preferred_Phone')}
                </div>
                {chairContact?.prefPhone}
              </div>
            </div>
            <div className="col-6">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Alternate_Phone')}
                </div>
                {chairContact?.altPhone}
              </div>
            </div>
            <div className="col-6">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Fax')}
                </div>
                {chairContact?.fax}
              </div>
            </div>
            <div className="col-6">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Email_Address')}
                </div>
                {chairContact?.email}
              </div>
            </div>
            <div className="col-6">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('First_Address')}
                </div>
                {chairContact?.addressOne}
              </div>
            </div>
            <div className="col-6">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Second_Address')}
                </div>
                {chairContact?.addressTwo}
              </div>
            </div>
            <div className="col-6">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('City')}
                </div>
                {chairContact?.city}
              </div>
            </div>
            <div className="col-6">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('State')}
                </div>
                {chairContact?.state}
              </div>
            </div>
            <div className="col-6">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Zip_Code')}
                </div>
                {chairContact?.zip}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Review;
