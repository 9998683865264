import {
  useEffect,
  useState,
} from 'react';
import { Table, Button, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { FaEdit } from 'react-icons/fa';
import {
  RequirementsData,
  CompensationData,
  LanguageData,
  LanguageResponseData,
} from '../services/mdp.service';
import {
  MinistryRequirementsData,
  PNCChairData,
  PNCChairDefaultData,
  CertificateData,
  CertificateResponseData,
  SelfReferralContactData,
  SelfReferralContactDefaultData,
  SelfReferralContactResponseData,
  MDPNarrativeData,
  ViewMDPResponseData,
  MDPOptionalLinkData,
  MDPOptionalLinkResponseData,
} from '../types/mdp.types';
import {
  MinistryProfile,
} from '../types/ministry.types';
import {
  ministryProfileState,
} from '../services/state.service';
import api from '../services/api.service';
import DisplayField from './generic/DisplayField';
import SanitizeHTML from '../services/html.service';

// Readonly Released MDP View
function ViewForm(): JSX.Element {
  const params = useParams();
  const { t } = useTranslation();
  const [requirements, setRequirements] = useState<RequirementsData>();
  const [certificateOptions, setCertificateOptions] = useState<CertificateData[]>([]);
  const [languageOptions, setLanguageOptions] = useState<LanguageData[]>([]);
  const [compensation, setCompensation] = useState<CompensationData>();
  const [ministryRequirements, setMinistryRequirements] = useState<MinistryRequirementsData>();
  const [ministryReference, setMinistryReference] = useState<any>([]);
  const [chairContact, setChairContact] = useState<PNCChairData>(PNCChairDefaultData);
  const [selfReferralContact, setSelfReferralContact] = useState<SelfReferralContactData>(SelfReferralContactDefaultData);
  const [ministryProfile, setMinistryProfileState] = useRecoilState<MinistryProfile>(ministryProfileState);
  const [MDPNarratives, setMDPNarratives] = useState<MDPNarrativeData[]>([]);
  const [yokedMinistryProfile, setYokedMinistryProfile] = useState<MinistryProfile[]>([]);
  const [optionalLink, setOptionalLink] = useState<MDPOptionalLinkData[]>([]);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(true);

  useEffect(() => {
    api.get(`/viewmdp/${params.id}/`).then(
      (response: ViewMDPResponseData) => {
        if (response.data) {
          setRequirements(response.data.requirements);
          setCompensation(response.data.compensation);
          setMinistryRequirements(response.data.ministryRequirements);
          setMinistryReference(response.data.references);
          setChairContact(response.data.chairContact);
          setMinistryProfileState(response.data.ministryProfile);
          setMDPNarratives(response.data.narratives);
          setYokedMinistryProfile(response.data.yokedMinistries);
          setLoading(false);
          setNotFound(false);
        }
      },
    ).catch(() => (setLoading(false)));
    api.get(`/selfreferralcontact/${params.id}/`).then(
      (response: SelfReferralContactResponseData) => {
        setSelfReferralContact(response.data.options);
      },
    ).catch(() => (setLoading(false)));
    api.get('/languages/').then(
      (response: LanguageResponseData) => (setLanguageOptions(response.data.options)),
    ).catch(() => (setLoading(false)));
    api.get('/certificates/').then(
      (response: CertificateResponseData) => (setCertificateOptions(response.data.options)),
    ).catch(() => (setLoading(false)));
    api.get(`/mdp/${params.id}/mdpoptionallinks/`).then(
      (response: MDPOptionalLinkResponseData) => (setOptionalLink(response.data)),
    );
  }, [params.id]);

  if (loading) {
    return (
      <Spinner
        animation="border"
        size="sm"
      />
    );
  }

  return notFound ? (
    <div className="container-fluid">
      <h1 className="text-center mt-3">{t('MDPNotFound')}</h1>
    </div>
  ) : (
    <div className="col-10 my-4 mx-auto">
      <div className="text-end">
        <Button
          className="mb-3 ms-auto printbutton"
          variant="primary"
          size="sm"
          active
          onClick={() => (window.print())}
        >
          <FaEdit />
          {` ${t('Print_Btn')}`}
        </Button>
      </div>
      <div className="mx-auto col-12">
        <div className="my-3 text-center title">{t('Ministry_General_Information')}</div>

        {/* Ministry Information */}
        <div className="border border-dark rounded-3 text-start mb-3">
          <div className="row">
            <div className="col-4">
              <div className="my-2 mx-3">
                <DisplayField
                  label={t('Ministry_Name')}
                  text={ministryProfile.ministryName}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="my-2 mx-3">
                <DisplayField
                  label={t('Presbytery')}
                  text={ministryProfile.presbytery}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="my-2 mx-3">
                <DisplayField
                  label={t('Ministry_Synod')}
                  text={ministryProfile.synod}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="my-2 mx-3">
                <DisplayField
                  label={t('Email')}
                  text={ministryProfile.email}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="my-2 mx-3">
                <DisplayField
                  label={t('Preferred_Phone')}
                  text={ministryProfile.preferredPhone}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="my-2 mx-3">
                <DisplayField
                  label={t('Ministry_Web_address')}
                  text={ministryProfile.website}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="my-2 mx-3">
                <DisplayField
                  label={t('Ministry_Mailing_address')}
                  text={ministryProfile.address}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="my-2 mx-3">
                <DisplayField
                  label={t('Ministry_Alt_phn_email')}
                  text={ministryProfile.altPhoneEmail}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="my-2 mx-3">
                <DisplayField
                  label={t('PDP.Community')}
                  text={ministryProfile?.congregationInfo?.communityType || ''}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="my-2 mx-3">
                <DisplayField
                  label={t('Ministry_Org_Size')}
                  text={ministryProfile?.congregationInfo?.congregationSize || ''}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="my-2 mx-3">
                <DisplayField
                  label={t('Ministry_Curriculum')}
                  text={ministryProfile?.congregationInfo?.curriculum || ''}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="my-2 mx-3">
                <DisplayField
                  label={t('Worship_Attendance')}
                  text={ministryProfile?.congregationInfo?.averageWorshipAttendance || ''}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="my-2 mx-3">
                <DisplayField
                  label={t('Church_School_Attendance')}
                  text={ministryProfile?.congregationInfo?.churchSchoolAttendance || ''}
                />
              </div>
            </div>
            <div className="col-10">
              <div className="my-2 mx-3">
                <DisplayField
                  label={t('Ministry_Intercultural_Comp')}
                  text={ministryProfile.congregationInfo?.interculturalComp || ''}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Yoked Ministry General Information */}
        {requirements?.positionTypeDescription === 'Pastor, Yoked Ministry' && (
          <div>
            {yokedMinistryProfile?.map((option: MinistryProfile) => (
              <div className="border border-dark rounded-3 text-start mb-3">
                <div className="row">
                  <div className="col-4">
                    <div className="my-2 mx-3">
                      <DisplayField
                        label={t('Ministry_Name')}
                        text={option.name}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="my-2 mx-3">
                      <DisplayField
                        label={t('Presbytery')}
                        text={option.presbytery}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="my-2 mx-3">
                      <DisplayField
                        label={t('Ministry_Synod')}
                        text={option.synod}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="my-2 mx-3">
                      <DisplayField
                        label={t('Email')}
                        text={option.email}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="my-2 mx-3">
                      <DisplayField
                        label={t('Preferred_Phone')}
                        text={option.preferredPhone}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="my-2 mx-3">
                      <DisplayField
                        label={t('Ministry_Web_address')}
                        text={option.website}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="my-2 mx-3">
                      <DisplayField
                        label={t('Ministry_Mailing_address')}
                        text={option.address}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="my-2 mx-3">
                      <DisplayField
                        label={t('Ministry_Alt_phn_email')}
                        text={option.altPhoneEmail}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="my-2 mx-3">
                      <DisplayField
                        label={t('PDP.Community')}
                        text={option?.congregationInfo?.communityType || ''}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="my-2 mx-3">
                      <DisplayField
                        label={t('Ministry_Org_Size')}
                        text={option?.congregationInfo?.congregationSize || ''}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="my-2 mx-3">
                      <DisplayField
                        label={t('Ministry_Curriculum')}
                        text={option?.congregationInfo?.curriculum || ''}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="my-2 mx-3">
                      <DisplayField
                        label={t('Worship_Attendance')}
                        text={option?.congregationInfo?.averageWorshipAttendance || ''}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="my-2 mx-3">
                      <DisplayField
                        label={t('Church_School_Attendance')}
                        text={option?.congregationInfo?.churchSchoolAttendance || ''}
                      />
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="my-2 mx-3">
                      <DisplayField
                        label={t('Ministry_Intercultural_Comp')}
                        text={option.congregationInfo?.interculturalComp || ''}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="my-3 text-center title">{t('Position_Information')}</div>
        {/* Position Requirements */}
        <div className="border border-dark rounded-3 text-start mb-3">
          <div className="my-4 mx-3 title">{t('Position_Requirements')}</div>
          <hr className="mdpdivider" />
          <div className="row">
            <div className="col-6">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Position_Types')}
                </div>
                {requirements?.positionTypeDescription}
              </div>
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('MDP.Experience_Required')}
                </div>
                {requirements?.experienceLevelDescription}
              </div>
              {requirements?.title && (
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Specify_Title')}
                  </div>
                  {requirements?.title}
                </div>
              )}
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Employment_Status')}
                </div>
                {requirements?.employmentTypeDescription}
              </div>
              {requirements?.certificate?.length !== 0 && (
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Training_Cert_Req')}
                  </div>
                  <div>
                    {requirements?.certificate.map((option: any) => (
                      <div key={`cert-${option.id}`}>
                        {certificateOptions.map((item: any) => (
                          (item.id === option)
                            ? (
                              <div key={item.description}>
                                {item.description}
                              </div>
                            )
                            : null))}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="col-6">
              {requirements?.othercert && (
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Other_Training')}
                  </div>
                  {requirements?.othercert}
                </div>
              )}
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Language_Requirements')}
                </div>
                <div>
                  {requirements?.language.map((option: any) => (
                    <div key={`language-${option}`}>
                      {languageOptions.map((item: any) => (
                        (item.id === option)
                          ? (
                            <div key={item.description}>
                              {item.description}
                            </div>
                          )
                          : null))}
                    </div>
                  ))}
                </div>
              </div>
              {requirements?.otherlang && (
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Other_Languages')}
                  </div>
                  {requirements?.otherlang}
                </div>
              )}
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('MDP.Statement_Required')}
                </div>
                {requirements?.statementRequired ? 'Yes' : 'No'}
              </div>
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Clergy_Couple')}
                </div>
                {requirements?.filingCouple ? 'Yes' : 'No'}
              </div>
              {requirements?.deadline && (
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Application_Deadline')}
                  </div>
                  {requirements?.deadline?.toString() || ''}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Ministry Requirements */}
        <div className="border border-dark rounded-3 text-start mb-3">
          <div className="my-4 mx-3 title">{t('Ministry_Requirements')}</div>
          <hr className="mdpdivider" />
          <div className="row">
            <div className="col-12">
              <div className="my-4 mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Church_Mission')}
                </div>
                <SanitizeHTML html={ministryRequirements?.vision} />
              </div>
              <div className="my-5 mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Tasks_Expectations')}
                </div>
                <SanitizeHTML html={ministryRequirements?.tasks} />
              </div>
            </div>
          </div>
        </div>

        {/* Compensation & Housing */}
        <div className="border border-dark rounded-3 text-start mb-3">
          <div className="my-4  mx-3 title">{t('Compensation_Housing')}</div>
          <hr className="mdpdivider" />
          <div className="row">
            <div className="col-6">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('PDP.Min_Expected_Salary')}
                </div>
                {compensation?.minimumExpectedSalary}
              </div>
            </div>
            <div className="col-6">
              <div className="my-4  mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Housing_Type')}
                </div>
                {compensation?.housingType}
              </div>
            </div>
          </div>
        </div>

        {/* Narratives */}
        <div className="border border-dark rounded-3 text-start mb-3">
          <div className="my-4  mx-3 title">{t('MDP_Narratives')}</div>
          <hr className="mdpdivider" />
          <div className="col-11 mt-4 ms-auto me-5">
            {MDPNarratives.map((option: MDPNarrativeData) => (
              <div key={`narrative-${option.id}`} className="border border-dark rounded-3 mb-3">
                <Table className="mdp-narrative-panel">
                  <thead>
                    <tr className="linktableheader">
                      <td>{option.question}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="mb-1">
                          <SanitizeHTML html={option.response || ''} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            ))}
          </div>
        </div>

        {/* Optional Links */}
        <div className="border border-dark rounded-3 text-start mb-3">
          <div className="my-4  mx-3 title">{t('MDP.Optional_Links')}</div>
          <hr className="mdpdivider" />
          <div className="row">
            <div className="col-6">
              <div className="my-4  mx-3">
                {optionalLink.map((option: any) => (
                  <div key={`optionallinks-${option.id}`} className="text-start my-4 mx-3">
                    <b>{option.linkTitle}</b>
                    &nbsp;-&nbsp;
                    {option.linkDescription}
                    &nbsp;-&nbsp;
                    <a
                      href={(option.linkUrl).startsWith('http') ? option.linkUrl : (`//${option.linkUrl}`)}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      {option.linkUrl}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* References */}
        <div className="border border-dark rounded-3 text-start mb-3">
          <div className="my-4 mx-3 title">{t('MDP.References')}</div>
          <hr className="mdpdivider" />
          <div className="row">
            <div className="col-4">
              <div className="my-4 mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Reference_One')}
                </div>
                <p>
                  {ministryReference[0]?.name}
                  <br />
                  {ministryReference[0]?.relation}
                  <br />
                  {ministryReference[0]?.phone}
                  <br />
                  <div className="mdp-ref-email">{ministryReference[0]?.email}</div>
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="my-4 mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Reference_Two')}
                </div>
                <p>
                  {ministryReference[1]?.name}
                  <br />
                  {ministryReference[1]?.relation}
                  <br />
                  {ministryReference[1]?.phone}
                  <br />
                  <div className="mdp-ref-email">{ministryReference[1]?.email}</div>
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="my-4 mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Reference_Three')}
                </div>
                <p>
                  {ministryReference[2]?.name}
                  <br />
                  {ministryReference[2]?.relation}
                  <br />
                  {ministryReference[2]?.phone}
                  <br />
                  <div className="mdp-ref-email">{ministryReference[2]?.email}</div>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="my-4 mx-3 title text-center">{t('SelfReferral_Contact_Info')}</div>
        {/* Self Referral Contact */}
        <div className="border border-dark rounded-3 text-start mb-3">
          {(selfReferralContact.COMDetails?.name
          || selfReferralContact.COMDetails?.phone
          || selfReferralContact.COMDetails?.email) && (
            <>
              <div className="row">
                {selfReferralContact.COMDetails?.name && (
                  <div className="col-6">
                    <div className="my-2 mx-3">
                      <div className="mb-2 reviewtitle">
                        {t('COM')}
                      </div>
                      {selfReferralContact.COMDetails?.name}
                    </div>
                  </div>
                )}
                {selfReferralContact.COMDetails?.phone && (
                  <div className="col-6">
                    <div className="my-2 mx-3">
                      <div className="mb-2 reviewtitle">
                        {t('Preferred_Phone')}
                      </div>
                      {selfReferralContact.COMDetails?.phone}
                    </div>
                  </div>
                )}
                {selfReferralContact.COMDetails?.email && (
                  <div className="col-6">
                    <div className="my-2 mx-3">
                      <div className="mb-2 reviewtitle">
                        {t('Email_Address')}
                      </div>
                      {selfReferralContact.COMDetails?.email}
                    </div>
                  </div>
                )}
              </div>
              <hr className="mdpdivider" />
            </>
          )}

          {(selfReferralContact.EPDetails?.name
          || selfReferralContact.EPDetails?.phone
          || selfReferralContact.EPDetails?.email) && (
            <>
              <div className="row">
                {selfReferralContact.EPDetails?.name && (
                  <div className="col-6">
                    <div className="my-2 mx-3">
                      <div className="mb-2 reviewtitle">
                        {t('EP')}
                      </div>
                      {selfReferralContact.EPDetails?.name}
                    </div>
                  </div>
                )}
                {selfReferralContact.EPDetails?.phone && (
                  <div className="col-6">
                    <div className="my-2 mx-3">
                      <div className="mb-2 reviewtitle">
                        {t('Preferred_Phone')}
                      </div>
                      {selfReferralContact.EPDetails?.phone}
                    </div>
                  </div>
                )}
                {selfReferralContact.EPDetails?.email && (
                  <div className="col-6">
                    <div className="my-2 mx-3">
                      <div className="mb-2 reviewtitle">
                        {t('Email_Address')}
                      </div>
                      {selfReferralContact.EPDetails?.email}
                    </div>
                  </div>
                )}
              </div>
              <hr className="mdpdivider" />
            </>
          )}
          {(chairContact?.name
          || chairContact?.addressOne
          || chairContact?.prefPhone) && (
            <div className="row">
              <div className="col-6">
                <div className="my-2 mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('PNC')}
                  </div>
                  {chairContact?.name}
                </div>
              </div>
              {chairContact?.addressOne
              && chairContact?.addressOne
              && chairContact?.city
              && chairContact?.state
              && chairContact?.zip && (
              <div className="col-6">
                <div className="my-2 mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Address')}
                  </div>
                  {`
                    ${chairContact?.addressOne || ''}
                    ${chairContact?.addressTwo || ''}
                    ${chairContact?.city || ''}
                    ${chairContact?.state || ''}
                    ${chairContact?.zip || ''}
                  `}
                </div>
              </div>
              )}
              {chairContact?.prefPhone && (
              <div className="col-6">
                <div className="my-2 mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Preferred_Phone')}
                  </div>
                  {chairContact?.prefPhone}
                </div>
              </div>
              )}
              {chairContact?.altPhone && (
              <div className="col-6">
                <div className="my-2 mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Alternate_Phone')}
                  </div>
                  {chairContact?.altPhone}
                </div>
              </div>
              )}
              {chairContact?.fax && (
                <div className="col-6">
                  <div className="my-2 mx-3">
                    <div className="mb-2 reviewtitle">
                      {t('Fax')}
                    </div>
                    {chairContact?.fax}
                  </div>
                </div>
              )}
              {chairContact?.email && (
                <div className="col-6">
                  <div className="my-2 mx-3">
                    <div className="mb-2 reviewtitle">
                      {t('Email_Address')}
                    </div>
                    {chairContact?.email}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewForm;
