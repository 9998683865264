import {
  useState,
  useEffect,
  useRef,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Button,
} from 'react-bootstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  SearchCommitteeData,
  SearchCommitteeResponseData,
} from '../types/ministry.types';
import {
  presbyteryMatchingState,
  currentRoleState,
} from '../services/state.service';
import {
  PresbyteryMatchingOptionData,
  PresbyteryMatchingOptionResponseData,
} from '../types/mdp.types';
import { PRESBYTERY_MATCHING_OPTIONS } from '../types/constants';
import api from '../services/api.service';
import { Role } from '../types/user.types';

type PresbyteryMatchingModalProps = {
  callback: (_result: boolean, _match: PresbyteryMatchingOptionData) => void,
  show: boolean,
  hide: () => void,
}

function PresbyteryMatchingModal({
  callback,
  show,
  hide,
}: PresbyteryMatchingModalProps): JSX.Element {
  const { t } = useTranslation();
  const [valid, setValid] = useState<boolean>(false);
  const [committees, setCommittees] = useState<SearchCommitteeData[]>([]);
  const [matchingOptions, setMatchingOptions] = useRecoilState<PresbyteryMatchingOptionData>(presbyteryMatchingState);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [refresh, setRefresh] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
  } = useForm<PresbyteryMatchingOptionData>();

  const submitRef = useRef<HTMLButtonElement>(null);

  const onSubmit: SubmitHandler<PresbyteryMatchingOptionData> = () => {
    callback(true, matchingOptions);
  };

  const searchCommitteeCheckboxes = committees.map((option: any) => (
    <Form.Check
      type="checkbox"
      checked={matchingOptions.matchByCommittee?.includes(option.id)}
      id={`committee-option-${option.id}`}
      key={`committee-option-${option.id}`}
      label={option.name}
      className="genericbutton ms-3 mt-2"
      onChange={() => (
        matchingOptions.matchByCommittee?.includes(option.id)
          ? setMatchingOptions((p: any) => ({
            ...p, matchByCommittee: matchingOptions.matchByCommittee.filter((item: any) => item !== option.id),
          }))
          : setMatchingOptions((p: any) => ({
            ...p, matchByCommittee: [...matchingOptions.matchByCommittee, option.id],
          }))
      )}
    />
  ));

  useEffect(() => {
    api.get(`presbyterymatching/${currentRole.organizationId}/`)
      .then(
        (response: PresbyteryMatchingOptionResponseData) => {
          if (response.data.options) {
            setMatchingOptions(response.data.options);
          }
        },
      );
    api.get(`/presbyterycommittees/${currentRole.organizationId}/`)
      .then(
        (response: SearchCommitteeResponseData) => {
          if (response.data.committees.length > 0) {
            setCommittees(response.data.committees);
          } else {
            setCommittees([]);
          }
        },
      );
    reset(matchingOptions);
  }, [refresh, currentRole]);

  useEffect(() => {
    if (matchingOptions.matchingChoice === PRESBYTERY_MATCHING_OPTIONS.none) {
      setValid(true);
    } else if (
      matchingOptions.matchingChoice
      && (matchingOptions.matchByCommittee.length > 0
      || matchingOptions.matchByOrdainedPositions)
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [matchingOptions]);

  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton />
      <Modal.Body className="text-center">
        <div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group
              className="mb-4"
            >
              <Form.Label
                className="col-12 mb-3 bodyheader"
              >
                {t('Presbytery_Matching')}
              </Form.Label>
              <Form.Check
                type="radio"
                checked={matchingOptions.matchingChoice === PRESBYTERY_MATCHING_OPTIONS.none || matchingOptions.matchingChoice == null}
                label={t('Match_By_None')}
                {...register('matchingChoice')}
                value={PRESBYTERY_MATCHING_OPTIONS.none}
                onChange={(e: any) => setMatchingOptions((p: any) => ({
                  ...p,
                  matchingChoice: PRESBYTERY_MATCHING_OPTIONS.none,
                  matchByOrdainedPositions: undefined,
                  matchByCommittee: [],
                }))}
              />
              <Form.Check
                type="radio"
                checked={matchingOptions.matchingChoice === PRESBYTERY_MATCHING_OPTIONS.ordained}
                label={t('Match_By_Ordained')}
                {...register('matchingChoice')}
                value={PRESBYTERY_MATCHING_OPTIONS.ordained}
                onChange={(e: any) => setMatchingOptions((p: any) => ({
                  ...p,
                  matchingChoice: PRESBYTERY_MATCHING_OPTIONS.ordained,
                  matchByOrdainedPositions: true,
                  matchByCommittee: [],
                }))}
              />
              <Form.Check
                type="radio"
                checked={matchingOptions.matchingChoice === PRESBYTERY_MATCHING_OPTIONS.committee}
                label={t('Match_By_Search_Committee')}
                {...register('matchingChoice')}
                value={PRESBYTERY_MATCHING_OPTIONS.committee}
                onChange={(e: any) => setMatchingOptions((p: any) => ({
                  ...p,
                  matchingChoice: PRESBYTERY_MATCHING_OPTIONS.committee,
                  matchByOrdainedPositions: undefined,
                }))}
              />
              {matchingOptions.matchingChoice === PRESBYTERY_MATCHING_OPTIONS.committee && (
                <div
                  className="offset-md-1 border rounded-3 state-checkbox-scroll"
                >
                  {searchCommitteeCheckboxes}
                </div>
              )}
            </Form.Group>
            <button
              ref={submitRef}
              type="submit"
              style={{ display: 'none' }}
              aria-label="submit"
            />
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => { callback(false, matchingOptions); setRefresh((r: boolean) => !r); }}
        >
          {t('PDP.Cancel')}
        </Button>
        <Button
          type="submit"
          variant="primary"
          className="col-2"
          onClick={() => submitRef.current?.click()}
          disabled={!valid}
        >
          {t('PDP.Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PresbyteryMatchingModal;
