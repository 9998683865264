import {
  useState,
  useEffect,
  ChangeEvent,
  useMemo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { debounce } from 'lodash';
import {
  PNCChairData,
  PNCChairResponseData,
  PNCChairDefaultData,
} from '../types/mdp.types';
import api from '../services/api.service';
import SanitizeHTML from '../services/html.service';
import { mdpStepState } from '../services/state.service';
import { useStepValid } from '../hooks/useStepValid';
import { StepProps } from '../types/generic.types';

function PNCChair({
  id,
}: StepProps): JSX.Element {
  const { t } = useTranslation();
  const [chairContact, setChairContact] = useState<PNCChairData>(PNCChairDefaultData);
  const [errors, setErrors] = useState<any>({});
  const [dataChanged, setDataChanged] = useState<boolean>(false);

  useEffect(() => {
    api.get(`/mdp/${id}/chair/`).then(
      (response: PNCChairResponseData) => {
        if (response.data.length > 0) {
          setChairContact({ ...response.data[0] });
        }
      },
    );
  }, []);

  const requiredFields = useMemo(() => (
    [
      'name',
      'prefPhone',
      'email',
      'addressOne',
      'city',
      'state',
      'zip',
    ]
  ), []);
  const valid = useStepValid('pncChair', chairContact, requiredFields, mdpStepState);

  useEffect(() => {
    const debouncedSave = debounce(() => {
      setErrors({});
      if (chairContact.id === 0) {
        api.post(`/mdp/${id}/chair/`, { ...chairContact, mdp: id })
          .catch((error) => {
            setErrors(error.response.data);
          });
      } else {
        api.put(`/mdp/${id}/chair/${chairContact.id}/`, { ...chairContact, mdp: id })
          .catch((error) => {
            setErrors(error.response.data);
          });
      }
    }, 1000);

    if (valid && dataChanged) {
      debouncedSave();
    }

    // Cleanup function to cancel the debounced call if the component unmounts
    return () => debouncedSave.cancel();
  }, [chairContact, valid, id, dataChanged]);

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { name, value } = event.target;
    setChairContact((prevState: PNCChairData) => ({ ...prevState, [name]: value }));
    setDataChanged(true);
  }, []);

  return (
    <div className="col-8 mx-auto">
      <div className="mx-auto">
        <div className="title">
          {t('PNC_Chair_Contact')}
        </div>
        <SanitizeHTML html={t('Chair_Contact_Info')} />
        <div className="my-3">
          <Form.Group className="text-start">
            <Form.Label className="bodyheader required">
              {t('Name')}
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={chairContact?.name || ''}
              onChange={(event) => handleInputChange(event)}
            />
          </Form.Group>
        </div>
        <div className="my-3">
          <Form.Group className="text-start">
            <Form.Label className="bodyheader required">
              {t('Preferred_Phone')}
            </Form.Label>
            <Form.Control
              type="text"
              name="prefPhone"
              value={chairContact?.prefPhone || ''}
              onChange={(event) => handleInputChange(event)}
            />
          </Form.Group>
        </div>
        <div className="my-3">
          <Form.Group className="text-start">
            <Form.Label className="bodyheader">
              {t('Alternate_Phone')}
            </Form.Label>
            <Form.Control
              type="text"
              name="altPhone"
              value={chairContact?.altPhone || ''}
              onChange={(event) => handleInputChange(event)}
            />
          </Form.Group>
        </div>
        <div className="my-3">
          <Form.Group className="text-start">
            <Form.Label className="bodyheader">
              {t('Fax')}
            </Form.Label>
            <Form.Control
              type="text"
              name="fax"
              value={chairContact?.fax || ''}
              onChange={(event) => handleInputChange(event)}
            />
          </Form.Group>
        </div>
        <div className="my-3">
          <Form.Group className="text-start">
            <Form.Label className="bodyheader required">
              {t('Email_Address')}
            </Form.Label>
            <Form.Control
              type="text"
              name="email"
              value={chairContact?.email || ''}
              onChange={(event) => handleInputChange(event)}
            />
          </Form.Group>
        </div>
        <div className="my-3">
          <Form.Group className="text-start">
            <Form.Label className="bodyheader required">
              {t('First_Address')}
            </Form.Label>
            <Form.Control
              type="text"
              name="addressOne"
              value={chairContact?.addressOne || ''}
              onChange={(event) => handleInputChange(event)}
            />
          </Form.Group>
        </div>
        <div className="my-3">
          <Form.Group className="text-start">
            <Form.Label className="bodyheader">
              {t('Second_Address')}
            </Form.Label>
            <Form.Control
              type="text"
              name="addressTwo"
              value={chairContact?.addressTwo || ''}
              onChange={(event) => handleInputChange(event)}
            />
          </Form.Group>
        </div>
        <div className="my-3">
          <Form.Group className="text-start">
            <Form.Label className="bodyheader required">
              {t('City')}
            </Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={chairContact?.city || ''}
              onChange={(event) => handleInputChange(event)}
            />
          </Form.Group>
        </div>
        <div className="my-3">
          <Form.Group className="text-start col-6">
            <Form.Label className="bodyheader required">
              {t('State')}
            </Form.Label>
            <Form.Control
              type="text"
              name="state"
              value={chairContact?.state || ''}
              onChange={(event) => handleInputChange(event)}
            />
          </Form.Group>
        </div>
        <div className="my-3">
          <Form.Group className="text-start col-6">
            <Form.Label className="bodyheader required">
              {t('Zip_Code')}
            </Form.Label>
            <Form.Control
              type="text"
              isInvalid={errors.zip}
              name="zip"
              value={chairContact?.zip || ''}
              onChange={(event) => handleInputChange(event)}
            />
            <Form.Control.Feedback type="invalid">
              {errors.zip}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>
    </div>
  );
}

export default PNCChair;
