import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  useNavigate,
  Navigate,
  Link,
} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import AuthService from '../../services/auth.service';
import UserService from '../../services/user.service';
import {
  appLoadingState,
  userProfileState,
  currentRoleState,
  statesListOptionsState,
} from '../../services/state.service';
import { UserProfile } from '../../types/user.types';
import { MinistryProfileResponseData } from '../../types/ministry.types';
import CLCFormSelect from '../../components/generic/CLCFormSelect';
import api from '../../services/api.service';
import { SelectOption } from '../../types/generic.types';
import {
  ISelectOption,
  ISelectOptionResponse,
} from '../../services/mdp.service';

function Login(): JSX.Element {
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm();
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    formState: {
      isSubmitting: isSubmittingSearch,
    },
  } = useForm();

  const PositionTypeDefaultData = {
    id: 0,
    value: '',
  };
  const navigate = useNavigate();
  const setUserProfileState = useSetRecoilState(userProfileState);
  const setCurrentRoleState = useSetRecoilState(currentRoleState);
  const setAppLoadingState = useSetRecoilState(appLoadingState);
  const [loginFailed, setLoginFailed] = useState(false);
  const [positionTypes, setPositionTypes] = useState<ISelectOption[]>([]);
  const userProfile = useRecoilValue<UserProfile>(userProfileState);
  const statesListOptions = useRecoilValue<SelectOption[]>(statesListOptionsState);

  useEffect(() => {
    api.get('/positiontypelist/').then(
      (response: ISelectOptionResponse) => (setPositionTypes(response.data.options)),
    );
  }, [setPositionTypes]);

  if (userProfile?.isLoggedIn) {
    return (<Navigate to="/" replace />);
  }

  function setDefaultCurrentRole(profile: UserProfile): void {
    if (profile.roles.length > 0) {
      setCurrentRoleState(profile.roles[0]);
    }
  }

  const onSubmit = handleSubmit((data: any) => (
    AuthService.Login(data.Username, data.Password)
      .then(() => UserService.GetProfile())
      .then((profile: UserProfile) => {
        setUserProfileState(profile);
        if (profile.currentRole) {
          const currentRole = profile.roles.find((p) => p.id === profile.currentRole);
          if (currentRole) {
            setCurrentRoleState(currentRole);
            if (currentRole.abbreviation === 'COS') {
              UserService.GetMinistryProfile(currentRole.organizationId).then(
                (r: MinistryProfileResponseData) => {
                  if (r.data && r.data.options) {
                    const ministryProfile = r.data.options;
                    if (ministryProfile.congregation && !ministryProfile.curriculum
                      && !ministryProfile.interculturalComp) {
                      navigate('/ministryprofilewizard', { replace: true });
                      setAppLoadingState(false);
                    }
                  }
                },
              );
            }
          } else {
            setDefaultCurrentRole(profile);
          }
        } else if (profile.roles.length > 0) {
          api.put('/user/profile/', { currentRole: profile.roles[0].id })
            .then(() => setCurrentRoleState(profile.roles[0]));
        }
        i18n.changeLanguage(profile.language);
        navigate('/');
      })
      .catch(() => {
        setLoginFailed(true);
      })
  ));

  const onSubmitSearch = handleSubmitSearch((data: any) => {
    let url = '?';

    if (data.positionType > 0) {
      url = url.concat(`positionType=${data.positionType}&`);
    }

    if (data.state !== '') {
      url = url.concat(`state=${data.state}&`);
    }

    navigate('/opportunity-search'.concat(url.slice(0, -1)));
  });

  return (
    <div className="container-fluid login-background">
      <div className="row">
        <div className="col-12 login-header">
          <h1>{t('Welcome_Title')}</h1>
          &nbsp;
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-12">
          <Form className="login-opp-search">
            <div className="row mb-4">
              <div className="offset-1 col-md-7 col-10">
                <div className="login-search-text">
                  <h2>{t('Discerning_Your_Call')}</h2>
                  <span>{t('Login_Page_Search')}</span>
                </div>
                <CLCFormSelect
                  defaultData={PositionTypeDefaultData}
                  formLabel={t('PDP.Position_Type_Title')}
                  inputName="positionType"
                  options={positionTypes}
                  register={registerSearch}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="offset-1 col-4">
                <CLCFormSelect
                  formLabel={t('State')}
                  inputName="state"
                  options={statesListOptions}
                  register={registerSearch}
                />
              </div>
              <div className="col-3 search-btn">
                <Button
                  type="submit"
                  variant="primary"
                  disabled={isSubmittingSearch}
                  onClick={onSubmitSearch}
                >
                  {isSubmittingSearch ? (
                    <Spinner
                      animation="border"
                      size="sm"
                    />
                  ) : (
                    t('Search')
                  )}
                </Button>
              </div>
            </div>
          </Form>
          &nbsp;
        </div>
        <div className="offset-1 col-md-5 col-10 login-panel">
          <Form className="login-form">
            <div className="row">
              <Form.Group
                className="col-12 col-md-6 mb-3 mx-auto"
                controlId="formUsername"
              >
                <Form.Label className="required">
                  {t('Login.Username')}
                </Form.Label>
                <Form.Control
                  type="input"
                  placeholder={t('Login.UsernamePlaceholder')}
                  isInvalid={!!errors.Username}
                  {...register('Username', { required: true })}
                />
                <Form.Control.Feedback type="invalid">
                  {t('Login.UsernameRequired')}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group
                className="col-12 col-md-6 mb-3 mx-auto"
                controlId="formPassword"
              >
                <Form.Label className="required">
                  {t('Password')}
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder={t('Password')}
                  isInvalid={!!errors.Password}
                  {...register('Password', { required: true })}
                />
                <Form.Control.Feedback type="invalid">
                  {t('Login.PasswordRequired')}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            {loginFailed && (
              <div className="col-12 mb-3 text-center non-field-error">
                {t('Login.InvalidCredentials')}
              </div>
            )}
            <div className="row text-center">
              <Button
                type="submit"
                variant="primary"
                className="login-submitbutton col-12 col-md-6 mb-3 mx-auto"
                disabled={isSubmitting}
                onClick={onSubmit}
              >
                {isSubmitting ? (
                  <Spinner
                    animation="border"
                    size="sm"
                  />
                ) : (
                  t('Login.Submit')
                )}
              </Button>
              <span className="login-links">
                <Link className="forgot-password-link" to="/signup">
                  <span>{t('DontHaveAccount')}</span>
                </Link>
                &nbsp;|&nbsp;
                <Link className="forgot-password-link" to="/forgot">
                  <span>{t('NeedHelpLoggingIn')}</span>
                </Link>
              </span>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;
