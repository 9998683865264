import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedTags: [
    'span', 'b', 'i', 'em', 'strong', 'p',
    'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol',
    'li', 'div', 'br',
  ],
  allowedAttributes: {
    span: ['class'],
  },
  allowedIframeHostnames: [],
};

const sanitize = (dirty: any, options: any): any => ({
  __html: sanitizeHtml(
    dirty,
    { ...defaultOptions, ...options },
  ),
});

function SanitizeHTML({ html, options }: any): JSX.Element {
  return (
    <div dangerouslySetInnerHTML={sanitize(html, options)} />
  );
}

export default SanitizeHTML;
