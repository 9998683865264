import { useEffect, useState } from 'react';
import { RecoilState, useRecoilState } from 'recoil';
import { StepWizardData } from '../types/generic.types';
import { cleanText } from '../utils';

export const useStepValid = (
  stepKey: string,
  data: any,
  requiredFields: string[],
  stateKey: RecoilState<StepWizardData[]>,
): boolean => {
  const [, setSteps] = useRecoilState<StepWizardData[]>(stateKey);
  const [valid, setValid] = useState<boolean>(false);
  let obj = data;

  // Can be an array or a simple object
  if (typeof data === 'object' && data.length > 0) {
    const [first] = data;
    obj = first;
  }

  useEffect(() => {
    const isValid = requiredFields.every((field: string) => {
      // Pass a specific value into the requiredFields
      // array to make it check for that specific value
      // JSON.parse converts the string into it's primitive type
      if (field.indexOf('=') !== -1) {
        const [key, value] = field.split('=');
        return obj[key] === JSON.parse(value);
      }
      if (Array.isArray(obj[field])) {
        return obj[field].length > 0;
      }

      if (field === 'vision') {
        if (cleanText(obj[field]).length > 2000) {
          return false;
        }
      }
      if (field === 'tasks') {
        if (cleanText(obj[field]).length > 2000) {
          return false;
        }
      }

      return obj[field] !== undefined && obj[field] !== null && obj[field] !== '';
    });

    setSteps((p: StepWizardData[]) => (
      p.map((b: StepWizardData) => {
        if (b.key === stepKey) {
          return { ...b, isValid };
        }

        return b;
      })
    ));
    setValid(isValid);
  }, [data]);

  return valid;
};
