import {
  useEffect,
  useState,
  Suspense,
  useCallback,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { FaLongArrowAltLeft, FaEdit } from 'react-icons/fa';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import PDPService from '../services/pdp.service';
import {
  IPositionType,
  IPositionTypeResponse,
  IExperienceLevel,
  PDPNarrativeData,
  PDPMatchProfileData,
  PDPMatchProfileDefaultData,
} from '../types/pdp.types';
import {
  PDPMatchData,
  PDPMatchDefaultData,
  PDPMatchResponseData,
} from '../types/mdp.types';
import {
  CallingInfoResponseData,
  PDPCallingInfoResponseData,
  CallingInfoData,
} from '../types/profile.types';
import api from '../services/api.service';
import {
  experienceLevelOptionsState,
  prefixDataState,
  suffixDataState,
} from '../services/state.service';
import {
  PrefixData,
  SuffixData,
} from '../types/user.types';
import { tryParseInt } from '../utils';
import SuspenseLoading from './generic/SuspenseLoading';
import MDPNotesModal from './MDPNotesModal';
import MDPService from '../services/mdp.service';
import DisplayField from './generic/DisplayField';
import SanitizeHTML from '../services/html.service';

function PDPMatchDetails(): JSX.Element {
  const { t } = useTranslation();
  const experienceLevels = useRecoilValue<IExperienceLevel[]>(experienceLevelOptionsState);
  const [filteredExpLevels, setFilteredExpLevels] = useState<IExperienceLevel[]>();
  const [positionTypeList, setPositionTypeList] = useState<IPositionType[]>([]);
  const [pdpMatchData, setPDPMatchData] = useState<PDPMatchData>(PDPMatchDefaultData);
  const [pdpMatchProfile, setPDPMatchProfileState] = useState<PDPMatchProfileData>(PDPMatchProfileDefaultData);
  const [eccInformation, setECCInformation] = useState<CallingInfoData[]>([]);
  const [showMDPNotes, setShowMDPNotes] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [displayPrint, setDisplayPrint] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlData = {
    pdpId: tryParseInt(params.get('pdp_id') || '') || 0,
    matchId: tryParseInt(params.get('match_id') || '') || 0,
    mdpId: params.get('mdp_id') || '',
    state: params.get('state') || '',
  };

  useEffect(() => {
    Promise.all([
      MDPService.GetPDPMatchProfile(urlData.matchId),
      PDPService.GetPositionTypes(urlData.pdpId.toString()),
      PDPService.GetPDPCallingInfo(urlData.matchId.toString()),
      MDPService.GetPDPMatch(urlData.mdpId),
    ]).then(([profile, positionTypes, callingInfo, matchData]) => {
      setPDPMatchProfileState(profile.data.options);
      setPositionTypeList(positionTypes.data.options);
      setECCInformation(callingInfo.data.options);
      setPDPMatchData(matchData.data.filter((i) => i.id === urlData.matchId)[0]);
      setDisplayPrint(true);
    });
  }, [refreshData]);

  useEffect(() => {
    const list = [...experienceLevels];
    list.splice(list.findIndex((p) => p.value === 'No Experience/First Ordained Call'), 1);
    setFilteredExpLevels(list);

    api.get('/profile/callinginfo/').then((response: CallingInfoResponseData) => {
      if (response.data[0].eccStatusValue === 'candidate') {
        setFilteredExpLevels(list.filter((p) => p.value === 'First Ordained Call'));
      }
    });
  }, [experienceLevels]);

  const closeNewEdit = useCallback((result: boolean, data: PDPMatchData): void => {
    if (result && data) {
      MDPService.UpdatePDPMatch(data).then(() => {
        setRefreshData((r: boolean) => !r);
        setShowMDPNotes(false);
      });
    } else {
      setShowMDPNotes(false);
    }
  }, []);

  function RenderPreferences(): JSX.Element {
    return (
      <div className="row">
        <div className="col-6">
          <div className="my-4 mx-3">
            <div className="mb-2 reviewtitle">
              {t('PDP.Employment_Type')}
            </div>
            {pdpMatchProfile.employmentType}
          </div>
        </div>
        <div className="col-6">
          <div className="my-4  mx-3">
            <div className="mb-2 reviewtitle">
              {t('PDP.Min_Expected_Salary')}
            </div>
            {pdpMatchProfile.minExpectedSalary}
          </div>
        </div>
      </div>
    );
  }

  function RenderFormalEducation(): JSX.Element {
    return (
      <div className="row">
        <div className="col-10 my-4 mx-3">
          <div className="mb-2 reviewtitle">
            {t('Training_Certification_Title')}
          </div>
          <div>
            {pdpMatchProfile.trainingCertification ? (
              pdpMatchProfile.trainingCertification?.map((option: any) => (
                <div key={`training-certification-${option.id}`}>
                  <SanitizeHTML html={`${t(option.weekOne)} ${option.weekOne ? ' - ' : ' '} ${option.weekOneLocation}`} />
                  <SanitizeHTML html={`${t(option.weekTwo)} ${option.weekOne ? ' - ' : ' '} ${option.weekTwoLocation}`} />
                  <SanitizeHTML html={option.cert} />
                </div>
              ))
            ) : (
              t('NotSpecified')
            )}
          </div>
          <div className="my-4 mb-2 reviewtitle">
            {t('Education_Title')}
          </div>
          {pdpMatchProfile.formalEducation?.map((option: any) => (
            <div key={`${option.credentials}-${option.id}`}>
              {`${option.credentials} - ${option.school},  (${option.start} ${option.current ? (t('- Current')) : (` - ${option.end}`)})`}
            </div>
          ))}
          <div className="my-4 mb-2 reviewtitle">
            {t('Continuing_Education')}
          </div>
          {pdpMatchProfile.continuingEducation?.map((option: any) => (
            <div key={`Education-${option.id}`}>
              <SanitizeHTML html={option.text} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  function RenderPersonalInfo(): JSX.Element {
    const prefix = useRecoilValue<PrefixData[]>(prefixDataState);
    const suffix = useRecoilValue<SuffixData[]>(suffixDataState);

    function displayPrefix(prefixId: number | string | undefined): string {
      let displayThisPrefix = '';
      for (let i = 0; i < prefix.length; i += 1) {
        if (prefix[i].id === prefixId) {
          displayThisPrefix = prefix[i].description;
        }
      }
      return displayThisPrefix;
    }

    function displaySuffix(suffixId: number | string | undefined): string {
      let displayThisSuffix = '';
      for (let i = 0; i < suffix.length; i += 1) {
        if (suffix[i].id === suffixId) {
          displayThisSuffix = suffix[i].description;
        }
      }
      return displayThisSuffix;
    }

    return (
      <div className="my-4 mx-3">
        <div className="row">
          <div className="mb-2 reviewtitle">
            <div className="row">
              <div className="col-sm-3">
                <DisplayField
                  label={t('Name')}
                  text={`
                  ${displayPrefix(pdpMatchProfile.userProfile?.prefix)}
                  ${pdpMatchProfile.userProfile?.firstName}
                  ${pdpMatchProfile.userProfile?.middleName}
                  ${pdpMatchProfile.userProfile?.lastName}
                  ${displaySuffix(pdpMatchProfile.userProfile?.suffix)}
                  `}
                />
              </div>
              <div className="col-sm-3">
                <DisplayField
                  label={t('Email')}
                  text={`${pdpMatchProfile.userProfile?.email}`}
                />
              </div>
              <div className="col-sm-3">
                <DisplayField
                  label={t('Pronouns')}
                  text={`${pdpMatchProfile.userProfile?.pronouns || ''}`}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <DisplayField
                  label={t('Address')}
                  text={`
                  ${pdpMatchProfile.userProfile?.addressOne || ''}
                  ${pdpMatchProfile.userProfile?.addressTwo || ''}
                  ${pdpMatchProfile.userProfile?.city || ''},
                  ${pdpMatchProfile.userProfile?.state || ''}
                  ${pdpMatchProfile.userProfile?.zip || ''}`}
                />
              </div>
              <div className="col-sm-3">
                <DisplayField
                  label={t('Phone_Number')}
                  text={pdpMatchProfile.userProfile?.phoneOne || ''}
                />
              </div>
              <div className="col-sm-3">
                <DisplayField
                  label={t('Secondary_Phone')}
                  text={pdpMatchProfile.userProfile?.phoneTwo || ''}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <DisplayField
                  label={t('EcclesiasticalStatus')}
                  text={`${eccInformation[0]?.eccStatusDescription}`}
                />
              </div>
              {eccInformation[0]?.presbyteryNonOrdainedDescription ? (
                <>
                  <div className="col-sm-3">
                    <DisplayField
                      label={t('PresbyteryMembership')}
                      text={`${eccInformation[0]?.presbyteryNonOrdainedDescription}`}
                    />
                  </div>
                  <div className="col-sm-3">
                    <DisplayField
                      label={t('Congregation')}
                      text={`${eccInformation[0]?.congregationDescription}`}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-sm-3">
                    <DisplayField
                      label={t('PresbyteryMembership')}
                      text={`${eccInformation[0]?.presbyteryDescription}`}
                    />
                  </div>
                  <div className="col-sm-3">
                    <DisplayField
                      label={t('OrdinationDate')}
                      text={`${eccInformation[0]?.ordinationDate}`}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-4 col-lg-11 col-12 mx-auto">
      <MDPNotesModal
        matchNote={pdpMatchData}
        show={showMDPNotes}
        saveMDPNotes={closeNewEdit}
      />
      <div className="mt-4 col-lg-10 col-12 mx-auto">
        <Link to={location.pathname.replace('pdpdetails', 'matches')} className="dashboard-link">
          <FaLongArrowAltLeft />
          <span>{t('Back_To_Matching_Results')}</span>
        </Link>
        <div className="mt-3 title text-center">
          {t('Matching Personal Discernment Profile')}
          <br />
          ID#&nbsp;
          { urlData.pdpId }
        </div>
        {
          displayPrint && (
            <div className="text-end">
              <Button
                className="mb-3 ms-auto printbutton"
                variant="primary"
                size="sm"
                active
                onClick={() => (window.print())}
              >
                <FaEdit />
                {` ${t('Print_Btn')}`}
              </Button>
            </div>
          )
        }
        <div className="mx-auto col-12 col-print">

          {/* Narratives */}
          <div className="border border-dark rounded-3 text-start mb-4">
            <div className="row">
              <div className="col-9 mt-4 mb-3 ms-3 fw-bold h4">{t('PDP.Narratives')}</div>
            </div>
            <hr className="mdpdivider" />
            <div className="row">
              <div className="col-11 mt-4 ms-auto me-5">
                {pdpMatchProfile.narratives?.length > 0 ? (pdpMatchProfile.narratives?.map((option: PDPNarrativeData) => (
                  <div key={option.id} className="border border-dark rounded-3 mb-3">
                    <Table className="pdp-narrative-panel">
                      <thead>
                        <tr className="linktableheader">
                          <td>{option.question}</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="text-center fw-bold">
                              {t('Applies_To_Position')}
                              &nbsp;
                              {option.positionTypes}
                            </div>
                            <hr />
                            <div className="mb-1">
                              <span>Response: </span>
                              <SanitizeHTML html={option.response || ''} />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ))) : (
                  <div className="text-center">{t('No_Narratives')}</div>
                )}
              </div>
            </div>
          </div>

          {/* Personal Information */}
          {pdpMatchProfile.userProfile?.userId > 0 && (
            <div className="border border-dark rounded-3 text-start mb-4">
              <div className="row">
                <div className="col-8 mt-4 mb-3 ms-3 fw-bold h4">
                  {t('Personal_Information')}
                </div>
              </div>
              <hr className="mdpdivider" />
              <Suspense fallback={<SuspenseLoading />}>
                <RenderPersonalInfo />
              </Suspense>
            </div>
          )}

          {/* Preferences */}
          <div className="border border-dark rounded-3 text-start mb-4">
            <div className="row">
              <div className="col-9 mt-4 mb-3 ms-3 fw-bold h4">
                {t('PDP.Preferences')}
              </div>
            </div>
            <hr className="mdpdivider" />
            <Suspense fallback={<SuspenseLoading />}>
              <RenderPreferences />
            </Suspense>
          </div>

          {/* Formal Education */}
          <div className="border border-dark rounded-3 text-start mb-4">
            <div className="row">
              <div className="col-9 mt-4 mb-3 ms-3 fw-bold h4">
                {t('Education_Title')}
              </div>
            </div>
            <hr className="mdpdivider" />
            <Suspense fallback={<SuspenseLoading />}>
              <RenderFormalEducation />
            </Suspense>
          </div>

          {/* Work Experience */}
          <div className="border border-dark rounded-3 text-start mb-4">
            <div className="row">
              <div className="col-9 mt-4 mb-3 ms-3 fw-bold h4">
                {t('PDP.Work_Experience')}
              </div>
            </div>
            <hr className="mdpdivider" />
            <div className="row">
              <div className="col-6">
                <div className="my-4 mx-3">
                  {pdpMatchProfile.workExperience?.map((work: any) => (
                    <div key={`workexp-${work.id}`} className="text-start my-4 mx-3">
                      <b>{new Intl.DateTimeFormat('en-US').format(new Date(work.start))}</b>
                      &nbsp;-&nbsp;
                      <b>{work.end ? new Intl.DateTimeFormat('en-US').format(new Date(work.end)) : t('Present')}</b>
                      <br />
                      {work.organization}
                      &nbsp;&nbsp;&nbsp;
                      {work.position}
                      <br />
                      {work.name}
                      <br />
                      {work.city}
                      ,&nbsp;
                      {work.state}
                      <br />
                      (
                      {work.community}
                      ,&nbsp;
                      {work.size}
                      )
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Service to the Church */}
          <div className="border border-dark rounded-3 text-start mb-4">
            <div className="row">
              <div className="col-9 mt-4 mb-3 ms-3 fw-bold h4">
                {t('PDP.Service_to_the_Church')}
              </div>
            </div>
            <hr className="mdpdivider" />
            <div className="row">
              <div className="col-6">
                <div className="my-4  mx-3">
                  {pdpMatchProfile.service?.map((option: any) => (
                    <div key={`servicetochurch-${option.id}`} className="text-start my-4 mx-3">
                      <b>{new Intl.DateTimeFormat('en-US').format(new Date(option.start))}</b>
                      &nbsp;-&nbsp;
                      <b>{option.end ? new Intl.DateTimeFormat('en-US').format(new Date(option.end)) : t('Present')}</b>
                      <br />
                      {option.position}
                      <br />
                      {option.congregationname}
                      ,&nbsp;
                      {option.presbyteryname}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Statement of Faith */}
          {pdpMatchProfile.statementOfFaith && (
            <div className="border border-dark rounded-3 text-start mb-4">
              <div className="row">
                <div className="col-9 mt-4 mb-3 ms-3 fw-bold h4">
                  {t('PDP.Statement_of_Faith')}
                </div>
                <hr className="mdpdivider" />
                <div className="col-12">
                  <div className="my-4  mx-3">
                    <SanitizeHTML html={pdpMatchProfile.statementOfFaith} />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Optional Links */}
          {pdpMatchProfile.optionalLinks?.length > 0 && (
            <div className="border border-dark rounded-3 text-start mb-4">
              <div className="row">
                <div className="col-9 mt-4 mb-3 ms-3 fw-bold h4">{t('PDP.Optional_Links')}</div>
              </div>
              <hr className="mdpdivider" />
              <div className="row">
                <div className="col-6">
                  <div className="my-4  mx-3">
                    {pdpMatchProfile.optionalLinks?.map((option: any) => (
                      <div key={`optionallinks-${option.id}`} className="text-start my-4 mx-3">
                        <b>{option.linkTitle}</b>
                        &nbsp;-&nbsp;
                        {option.linkDescription}
                        &nbsp;-&nbsp;
                        <a
                          href={(option.linkUrl).startsWith('http') ? option.linkUrl : (`//${option.linkUrl}`)}
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          {option.linkUrl}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* References */}
          {pdpMatchProfile.reference?.length > 0 && (
            <div className="border border-dark rounded-3 text-start mb-4">
              <div className="row">
                <div className="col-9 mt-4 mb-3 ms-3 fw-bold h4">{t('PDP.References')}</div>
              </div>
              <hr className="mdpdivider" />
              <div className="row">
                <div className="col-6">
                  <div className="my-4  mx-3">
                    {pdpMatchProfile.reference?.map((option: any, index: any) => (
                      <div key={`references-${option.id}`}>
                        <div className="mx-3 mb-2 reviewtitle">
                          {t('PDP.Singular_Reference')}
                          &nbsp;#
                          {index + 1}
                        </div>
                        <div className="text-start my-3 mx-3">
                          <b>{option.name}</b>
                          <br />
                          {option.relation}
                          <br />
                          {option.phone}
                          <br />
                          {option.email}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Sexual Misconduct */}
          <div className="title text-center mt-4">
            {t('PDP.Misconduct_Cert_Title')}
          </div>
          <div className="border border-dark rounded-3 text-start mb-4">
            <div className="row">
              <div className=" my-4  mx-3 col-12 text-wrap">
                {eccInformation[0]?.noComplaint ? (
                  <div>{t('PDP.Misconduct_Certify')}</div>
                ) : (
                  <>
                    <div>{t('PDP.Misconduct_Unable')}</div>
                    <br />
                    <div>{eccInformation[0]?.comments}</div>
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div className="my-4 mx-3 col-12 text-wrap">
                {eccInformation[0]?.certificationAccepted && (
                  <div>{t('PDP.Misconduct_Certification')}</div>
                )}
              </div>
            </div>
          </div>

          {/* MDP Notes */}
          <div className="border border-dark rounded-3 text-start mb-4">
            <div className="row">
              <div className="col-8 mt-4 mb-3 ms-3 fw-bold h4">
                {t('MDP_Notes')}
              </div>
              <div className="col-3 d-flex">
                <Button
                  type="button"
                  className="mb-3 ms-auto createbutton note-btn"
                  variant="primary"
                  size="sm"
                  active
                  onClick={() => { setShowMDPNotes(true); }}
                >
                  {t('Edit_Note')}
                </Button>
              </div>
            </div>
            <hr className="mdpdivider" />
            <div className="my-4 mx-3">
              <div className="row">
                {pdpMatchProfile?.note ? (
                  <div className="mb-2 reviewtitle">
                    {pdpMatchProfile?.note}
                  </div>
                ) : (
                  <div className="mb-2 reviewtitle text-center">
                    {t('CommitteeNotes_Placeholder')}
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default PDPMatchDetails;
