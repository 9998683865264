import {
  useRef,
  useState,
} from 'react';
import {
  Button,
  Modal,
  Form,
  Table,
} from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import {
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import {
  FaPlus,
  FaUsers,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import withUserAllowed from './layout/withUserAllowed';
import { CLC_FUNCTION } from '../types/constants';
import {
  CommitteeMember,
  SearchCommitteeData,
} from '../types/ministry.types';
import api from '../services/api.service';
import { currentRoleState } from '../services/state.service';
import { Role } from '../types/user.types';

type CommitteeMemberForm = {
  first_name: string,
  last_name: string,
  email: string,
  role: string,
  committee: string,
}

function SearchCommitteeMembers({
  committee,
  updateCallback,
}: {
  committee: SearchCommitteeData,
  updateCallback: () => void,
}): JSX.Element {
  const { t } = useTranslation();
  const [showInvite, setShowInvite] = useState(false);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const submitRef = useRef<HTMLButtonElement>(null);
  const {
    handleSubmit,
    register,
    reset,
    formState: {
      errors,
    },
  } = useForm<CommitteeMemberForm>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      role: 'PNC Member',
      committee: committee.id.toString(),
    },
  });

  function removeMember(member: CommitteeMember): Promise<void> {
    const payload = new FormData();
    payload.append('id', member.id.toString());
    payload.append('committee', committee.id.toString());
    payload.append('expected', member.expected ? 'True' : 'False');
    return api.post('/removecommitteemember/', payload)
      .then(() => (updateCallback()));
  }

  function getActions(member: CommitteeMember): JSX.Element {
    return (
      <Button variant="secondary" className="action-button" onClick={() => removeMember(member)}>
        {t('Remove')}
      </Button>
    );
  }

  function Invite(): JSX.Element {
    const handleClose = (): void => setShowInvite(false);
    const handleShow = (): void => setShowInvite(true);

    const onSubmit: SubmitHandler<CommitteeMemberForm> = (data: CommitteeMemberForm) => {
      const payload = new FormData();
      payload.append('first_name', data.first_name);
      payload.append('last_name', data.last_name);
      payload.append('email', data.email);
      payload.append('role', data.role);
      payload.append('committee', committee.id.toString());
      api.post(`/committeemembers/${committee.id}/`, payload)
        .then(() => {
          setShowInvite(false);
          reset();
          updateCallback();
        });
    };

    return (
      <>
        <Button
          onClick={handleShow}
          className="mb-3 ms-auto createbutton"
          variant="primary"
          size="sm"
          active
        >
          <FaPlus />
          {` ${t('Invite')}`}
        </Button>

        <Modal
          show={showInvite}
          onHide={handleClose}
          animation={false}
          className="modalstyle"
          backdrop="static"
        >
          <Modal.Header closeButton />
          <Modal.Body className="text-center">
            <div className="mx-auto mt-3">
              <div className="title">{t('InviteCommitteeMember')}</div>
            </div>
            <Form className="mx-auto col-8" onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('First_Name')}
                </Form.Label>
                <Form.Control
                  {...register('first_name', { required: true })}
                  isInvalid={!!errors.first_name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.first_name && errors.first_name.message
                    ? errors.first_name.message
                    : t('FirstNameRequired')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('Last_Name')}
                </Form.Label>
                <Form.Control
                  {...register('last_name', { required: true })}
                  isInvalid={!!errors.last_name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.last_name && errors.last_name.message
                    ? errors.last_name.message
                    : t('LastNameRequired')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('Email')}
                </Form.Label>
                <Form.Control
                  {...register('email', { required: true })}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email && errors.email.message
                    ? errors.email.message
                    : t('EmailRequired')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('Role')}
                </Form.Label>
                <Form.Select
                  {...register('role')}
                >
                  <option key="pncm" value="PNC Member">
                    {t('PNC_Member')}
                  </option>
                  <option key="pncc" value="PNC Chair">
                    {t('PNC_Chair')}
                  </option>
                </Form.Select>
              </Form.Group>
              <button
                ref={submitRef}
                type="submit"
                style={{ display: 'none' }}
                aria-label="submit"
              />
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex">
            <Button variant="primary" onClick={() => submitRef.current?.click()}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <>
      <div>
        <FaUsers />
        <span className="accordion-header-label">
          {t('Committee Members')}
        </span>
      </div>
      <div className="d-flex">
        {currentRole.functions.indexOf(CLC_FUNCTION.manageSearchCommittees) > -1 && Invite()}
      </div>
      <div className="border mb-5 border-dark rounded-3">
        <Table responsive hover className="mb-3 linktable">
          <thead>
            <tr className="linktableheader">
              <th>
                {t('Name')}
              </th>
              <th>
                {t('Email')}
              </th>
              <th>
                {t('Role')}
              </th>
              <th>
                {t('Status')}
              </th>
              <th>
                {t('Actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {committee.members?.map((user: CommitteeMember) => (
              <tr key={user.id + user.email} className="dividinglines">
                <td>
                  {user.firstName}
                  &nbsp;
                  {user.lastName}
                </td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>{user.expected ? t('Invited') : t('Active')}</td>
                <td>
                  {currentRole.functions.indexOf(CLC_FUNCTION.manageSearchCommittees) > -1 && getActions(user)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default withUserAllowed([CLC_FUNCTION.manageSearchCommittees, CLC_FUNCTION.viewMatches].join(','))(SearchCommitteeMembers);
