import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
} from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  MDPData,
  MDPListResponseData,
  MatchData,
  MatchResponseData,
  PDPMatchResponseData,
  ReferData,
} from '../types/mdp.types';
import api from '../services/api.service';
import AddButton from '../components/generic/AddButton';
import ManageButton from '../components/generic/ManageButton';
import ViewButton from '../components/generic/ViewButton';
import PrintButton from '../components/generic/PrintButton';
import ReferButton from '../components/generic/ReferButton';
import ManageMatchingModal from '../components/ManageMatchingModal';
import {
  currentRoleState,
  matchState,
  mdpPanelState,
} from '../services/state.service';
import MDPService from '../services/mdp.service';
import withUserAllowed from '../components/layout/withUserAllowed';
import { CLC_FUNCTION } from '../types/constants';
import { Role } from '../types/user.types';
import AlertModal from '../components/generic/AlertModal';
import ReferModal from '../components/ReferModal';

function MDPMidCouncilPanel(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setMatch] = useRecoilState<MatchData>(matchState);
  const [list, setList] = useState<MDPData[]>([]);
  const [refreshList, setRefreshList] = useState(false);
  const [showNoMatchModal, setShowNoMatchModal] = useState(false);
  const [showManageMatchModal, setShowManageMatchModal] = useState(false);
  const [showReferModal, setShowReferModal] = useState(false);
  const [showPDPReferredModal, setShowPDPReferredModal] = useState(false);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [advancedSearch, setAdvancedSearch] = useState<boolean>(false);
  const [currentMDP, setCurrentMDP] = useState<string>('');
  const refreshPanel = useRecoilValue(mdpPanelState);

  useEffect(() => {
    setAdvancedSearch(currentRole.functions.indexOf(CLC_FUNCTION.advancedSearch) > -1);
  }, [currentRole]);

  useEffect(() => {
    if (currentRole.organizationId) {
      api.get(`/mdp/?organization=${currentRole.organizationId}`).then(
        (response: MDPListResponseData) => {
          if (response.data.length > 0) {
            setList(response.data.sort((a, b) => (parseInt(a.id, 10) - parseInt(b.id, 10))));
          } else {
            setList([]);
          }
        },
      );
    }
  }, [currentRole, refreshList, refreshPanel]);

  const closeNewEdit = useCallback((result: boolean, match: MatchData): void => {
    if (result && match) {
      if (match.id > 0) {
        api.put(`/mdp/${match.mdp}/matches/${match.id}/`, match).then(() => {
          setShowManageMatchModal(false);
          setRefreshList((r: boolean) => !r);
          MDPService.GetPDPMatches(match.mdp.toString())
            .then((response: PDPMatchResponseData) => {
              if (response.data.length === 0) {
                setShowNoMatchModal(true);
              }
            });
        });
      }
    } else {
      setShowManageMatchModal(false);
    }
  }, []);

  const closeRefer = useCallback((result: boolean, referral: ReferData): void => {
    if (result && referral.pdp_id && referral.mdp_id) {
      const payload = new FormData();
      payload.append('pdp_id', referral.pdp_id);
      payload.append('mdp_id', referral.mdp_id);
      api.post('/refer/', payload).then(() => {
        setShowReferModal(false);
        setCurrentMDP('');
        setShowPDPReferredModal(true);
        setRefreshList((r: boolean) => !r);
      });
    } else {
      setShowReferModal(false);
      setCurrentMDP('');
    }
  }, []);

  const refer = (id: string): void => {
    setCurrentMDP(id);
    setShowReferModal(true);
  };

  const getMatches = (id: string): void => {
    api.get(`/mdp/${id}/matches/`).then(
      (response: MatchResponseData) => {
        if (response.data) {
          const result = response.data[0];
          const matchData = {
            ...result,
            matchCharacteristics: result.matchCharacteristics.sort((a, b) => a.characteristic - b.characteristic),
          };
          setMatch(matchData);
          setShowManageMatchModal(true);
        }
      },
    );
  };

  function renderMatchButtons(option: MDPData): any {
    const callingDate = new Date(option.callingStart);
    const current = new Date();
    return option.hasMatches ? (
      <>
        {((callingDate && (callingDate > current)) || option.callingStart === '') && (
          <ManageButton
            label=""
            handler={() => {
              getMatches(option.id);
            }}
          />
        )}
        <ViewButton
          label=""
          handler={() => (
            navigate(`/mdp/${(option.id)}/matches/`)
          )}
        />
        {((callingDate && (callingDate > current)) || option.callingStart === '') && (
          <ReferButton
            label=""
            handler={() => {
              refer(option.id);
            }}
          />
        )}
      </>
    ) : (
      <>
        <AddButton
          label=""
          handler={() => {
            getMatches(option.id);
          }}
        />
        <ReferButton
          label=""
          handler={() => {
            refer(option.id);
          }}
        />
      </>
    );
  }

  function renderPrintButton(id: string): any {
    return (
      <PrintButton
        label=""
        handler={() => (
          navigate(`/mdp/${id}/view/`)
        )}
      />
    );
  }

  function getStatus(mdp: MDPData): string {
    const callingDate = new Date(mdp.callingStart);
    const current = new Date();

    if (!mdp.submitted && !mdp.authorized) {
      return t('InProgress');
    }

    if (mdp.submitted && !mdp.authorized) {
      return t('Submitted');
    }

    if (callingDate && (callingDate <= current)) {
      return t('Calling_Notify_Completed');
    }

    if (callingDate && (callingDate > current)) {
      return t('Calling_Notify_Pending');
    }

    return t('Released');
  }

  function renderPDPTable(pdpList: MDPData[], advSearch: boolean): JSX.Element {
    return (
      <tbody>
        {pdpList?.map((option: MDPData) => (
          <tr key={option.id} className="dividinglines">
            <td>{option.id}</td>
            <td>
              {option.positionType ? option.positionType : ''}
              {option.positionTitle ? ` (${option.positionTitle})` : ''}
            </td>
            <td>{option.organizationName ? option.organizationName : '' }</td>
            <td>{getStatus(option)}</td>
            <td>
              <div>
                <ManageMatchingModal
                  callback={closeNewEdit}
                  show={showManageMatchModal}
                  hide={() => setShowManageMatchModal(false)}
                />
                {renderPrintButton(option.id)}
                { advSearch && renderMatchButtons(option) }
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }

  return (
    <div className="mb-3 mx-auto mobiletable col-lg-12">
      <AlertModal
        show={showNoMatchModal}
        title={t('NoMatch')}
        description={t('NoMatchDescription')}
        closeLabel={t('OK')}
        callback={() => setShowNoMatchModal(!showNoMatchModal)}
      />
      <AlertModal
        show={showPDPReferredModal}
        title={t('PDP_Referred')}
        description={t('PDP_Referred_Description')}
        closeLabel={t('OK')}
        callback={() => setShowPDPReferredModal(!showPDPReferredModal)}
      />
      <ReferModal
        mdp={currentMDP}
        callback={closeRefer}
        show={showReferModal}
        hide={() => { setShowReferModal(false); setRefreshList((r: boolean) => !r); }}
      />
      <div className="border border-dark rounded-3">
        <Table responsive hover className="mb-3 linktable">
          <thead>
            <tr className="linktableheader">
              <th>{t('Links_ID')}</th>
              <th>{t('PDP.Position')}</th>
              <th>{t('Organization')}</th>
              <th>{t('Status')}</th>
              <th>{t('Links_Table_Actions')}</th>
            </tr>
          </thead>
          { renderPDPTable(list, advancedSearch) }
        </Table>
      </div>
    </div>
  );
}

export default withUserAllowed(CLC_FUNCTION.approveMDP)(MDPMidCouncilPanel);
